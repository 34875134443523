import axios from "axios";
import Global from "../../Globa";
import { DMRequestResponse, DMSettings, DMStock } from "../DataModel/DataModel";

export default function DBStock()
{
    function List(productID) : Promise<DMStock[]> {
        
        return new Promise(function (resolve, reject) {
            axios.post(Global().URL, {
                command: 'product_stock_list',
                productID: productID
            })
                .then(function (response) {

                    alert(JSON.stringify(response.data));
                    
                    var itemList: DMStock[] = [];
                    
                    for(var i = 0 ; i<response.data.length;i++)
                    {
                        itemList.push(new DMStock(response.data[i]))
                    }

                    resolve(itemList);
                })
                .catch(function (error) {
                    console.log(error);
                });
        });
    
    }
    function Remove(stockID): Promise<DMRequestResponse[]>  {
        console.log(stockID)
        return new Promise((ok, fail) => {
            axios.post(Global().URL, {
                command: 'product_stock_remove',
                stockID: stockID
            })
                .then(function (response) {
                    console.log(response);
                    ok(response.data);
                })
                .catch(function (error) {
                    console.log(error);
                });
        })
    }
    function Update()
    {

    }
    function Insert(firstOptionGroup, secondOptionGroup, buyPrice, sellPrice, stock, date, productID) : Promise<DMRequestResponse[]> {
        return new Promise((ok, fail) => {
            axios.post(Global().URL, {
                command: 'product_stock_insert',
                firstOptionGroup: firstOptionGroup,
                secondOptionGroup: secondOptionGroup,
                buyPrice: buyPrice,
                sellPrice: sellPrice,
                stock: stock,
                date: date,
                productID: productID
            })
                .then(function (response) {
                    console.log(response)
                    ok(response.data);
                })
                .catch(function (error) {
                    console.log(error);
                });
        })
    }
    return {
        List,Insert,Remove
    }
}