import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import React, { useEffect, useRef, useState } from "react"
import DataCenter from '../../Core/Database/DataCenter';
export default function CategoryModal(props) {
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const [inputCategory, setInputCategory] = useState("");

    function DoneClickEvent() {

        if (props.type == "update") {
            DataCenter().Product.Category.Update(props.item.ek_id,inputCategory).then(() => {
                props.onUpdate(inputCategory,props.item.ek_id)
                handleClose();
            });
        }
        else
        {
            
            DataCenter().Product.Category.Insert(inputCategory).then(() => {
                props.onDone(inputCategory)
                handleClose();
            });
        }

      
    }


    function RemoveClickEvent() {

        DataCenter().Product.Category.Remove(props.item.ek_id).then(() => {
            props.onRemove(props.item.ek_id)
            handleClose();
        });

    }
    function handleShow() {
        if (props.type == "update") {
            setInputCategory(props.item.ek_category_name);
        }

        setShow(true);
    }
    return (
        <>
            <Button variant={props.type == "insert" ? "primary" : "light-primary"} onClick={handleShow}>
                {props.type == "insert" ? "Yeni bir kategori oluştur" : "Güncelle"}
            </Button>

            <Modal show={show} onHide={handleClose} animation={false}>
                <Modal.Header closeButton>
                    <Modal.Title>Kategori Güncelle</Modal.Title>
                </Modal.Header>
                <Modal.Body>

                    <div className="form-group">
                        <label>Kategori Adı
                            <span className="text-danger">*</span></label>
                        <input onChange={(e) => { setInputCategory(e.target.value) }} value={inputCategory} type="email" className="form-control" placeholder="Kategori Adı" />
                        <span className="form-text text-muted">Bu alanı boş bırakamazsınız</span>
                    </div>

                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Vazgeç
                    </Button>
                    {props.type == "insert" ? "" : <Button variant="danger" onClick={RemoveClickEvent}>
                        Sil
                    </Button>}

                    <Button variant="primary" onClick={DoneClickEvent}>
                        {props.type == "insert" ? "Ekle" : "Güncelle"}
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
}

