import axios from "axios";
import Global from "../../Globa";
import { DMOrders, DMRequestResponse } from "../DataModel/DataModel";



export default function DBOrders()
{
 
    function List() : Promise<DMOrders[]> {
        return new Promise((resolve, fail) => {
            axios.post(Global().URL, {
              command: 'order_admin_list',
            })
              .then(function (response) {
                var itemList: DMOrders[] = [];
                    
                for(var i = 0 ; i<response.data.length;i++)
                {
                    itemList.push(new DMOrders(response.data[i]))
                }

                resolve(itemList);
              })
              .catch(function (error) {
                console.log(error);
              });
          })
    
    }
    function Detail(id) : Promise<DMOrders> {
        return new Promise((ok, fail) => {
            axios.post(Global().URL, {
              command: 'order_user_detail',
              orderID: id
            })
              .then(function (response) {
      
                console.log(response.data)
                ok(new DMOrders(response.data));
              })
              .catch(function (error) {
                console.log(error);
              });
          })
    
    }

    function UpdateStatus(id, status) : Promise<DMRequestResponse> 
    {
        return new Promise((ok, fail) => {
            axios.post(Global().URL, {
              command: 'order_status_change',
              orderID: id,
              orderStatus: status,
            })
              .then(function (response) {
      
                console.log(response.data)
                ok(response.data);
              })
              .catch(function (error) {
                console.log(error);
              });
          })
    }
    function UpdateCargoNumber(id, status) : Promise<DMRequestResponse> 
    {
        return new Promise((ok, fail) => {
            axios.post(Global().URL, {
              command: 'order_set_cargo_number',
              orderID: id,
              cargoCode: status,
            })
              .then(function (response) {
      
                console.log(response.data)
                ok(response.data);
              })
              .catch(function (error) {
                console.log(error);
              });
          })
    }
    return {
        List,Detail,UpdateStatus,UpdateCargoNumber
    }
}