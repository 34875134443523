import axios from "axios";
import Global from "../../Globa";
import { DMProductCategory, DMRequestResponse } from "../DataModel/DataModel";

export default function DBCategory()
{

    function List() : Promise<DMProductCategory[]> {
        
        return new Promise(function (resolve, reject) {
            axios.post(Global().URL, {
                command: 'product_category_list',
            })
                .then(function (response) {
                    var itemList: DMProductCategory[] = [];
                    console.log((response.data))
                    
                    for(var i = 0 ; i<response.data.length;i++)
                    {
                        itemList.push(new DMProductCategory(response.data[i]))
                    }

                    resolve(itemList);
                })
                .catch(function (error) {
                    console.log(error);
                });
        });
    
    }

    function Update(id,name) : Promise<DMRequestResponse>
    {
        return new Promise((ok, fail) => {
            axios.post(Global().URL, {
                command: 'product_category_update',
                categoryID: id,
                categoryName: name,
            })
                .then(function (response) {
                    ok(response.data);
                })
                .catch(function (error) {
                    console.log(error);
                });
        })
    }
    function Insert(name) : Promise<DMRequestResponse>
    {
        return new Promise((ok, fail) => {
            axios.post(Global().URL, {
                command: 'product_category_insert',
                categoryName: name,
            })
                .then(function (response) {
                    ok(response.data);
                })
                .catch(function (error) {
                    console.log(error);
                });
        })
    }
    function Remove(id) : Promise<DMRequestResponse>
    {
        return new Promise((ok, fail) => {
            axios.post(Global().URL, {
                command: 'product_category_delete',
                categoryID: id
            })
                .then(function (response) {
                    ok(response.data);
                })
                .catch(function (error) {
                    console.log(error);
                });
        })
    }
    return {
        List,Remove,Update,Insert
    }
}