export default function Home() {



    return <>
        <div className="row">

            <div className="col-xl-4">
                {/*begin::Mixed Widget 18*/}
                <div className="card card-custom gutter-b">
                    {/*begin::Header*/}
                    <div className="card-header border-0 pt-5">
                        <div className="card-title font-weight-bolder">
                            <div className="card-label">Haftalık Satış Özeti
                                <div className="font-size-sm text-muted mt-2">890,344 Satış</div></div>
                        </div>
                        <div className="card-toolbar">
                            <div className="dropdown dropdown-inline">
                                <a href="#" className="btn btn-clean btn-sm btn-icon" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                    <i className="ki ki-bold-more-hor" />
                                </a>
                                <div className="dropdown-menu dropdown-menu-md dropdown-menu-right">
                                    {/*begin::Navigation*/}
                                    <ul className="navi navi-hover">
                                        <li className="navi-header font-weight-bold py-4">
                                            <span className="font-size-lg">Choose Label:</span>
                                            <i className="flaticon2-information icon-md text-muted" data-toggle="tooltip" data-placement="right" title data-original-title="Click to learn more..." />
                                        </li>
                                        <li className="navi-separator mb-3 opacity-70" />
                                        <li className="navi-item">
                                            <a href="#" className="navi-link">
                                                <span className="navi-text">
                                                    <span className="label label-xl label-inline label-light-success">Customer</span>
                                                </span>
                                            </a>
                                        </li>
                                        <li className="navi-item">
                                            <a href="#" className="navi-link">
                                                <span className="navi-text">
                                                    <span className="label label-xl label-inline label-light-danger">Partner</span>
                                                </span>
                                            </a>
                                        </li>
                                        <li className="navi-item">
                                            <a href="#" className="navi-link">
                                                <span className="navi-text">
                                                    <span className="label label-xl label-inline label-light-warning">Suplier</span>
                                                </span>
                                            </a>
                                        </li>
                                        <li className="navi-item">
                                            <a href="#" className="navi-link">
                                                <span className="navi-text">
                                                    <span className="label label-xl label-inline label-light-primary">Member</span>
                                                </span>
                                            </a>
                                        </li>
                                        <li className="navi-item">
                                            <a href="#" className="navi-link">
                                                <span className="navi-text">
                                                    <span className="label label-xl label-inline label-light-dark">Staff</span>
                                                </span>
                                            </a>
                                        </li>
                                        <li className="navi-separator mt-3 opacity-70" />
                                        <li className="navi-footer py-4">
                                            <a className="btn btn-clean font-weight-bold btn-sm" href="#">
                                                <i className="ki ki-plus icon-sm" />Add new</a>
                                        </li>
                                    </ul>
                                    {/*end::Navigation*/}
                                </div>
                            </div>
                        </div>
                    </div>
                    {/*end::Header*/}
                    {/*begin::Body*/}
                    <div className="card-body" style={{ position: 'relative' }}>


                        <div className="mt-6 position-relative zindex-0">
                            {/*begin::Widget Item*/}
                            <div className="d-flex align-items-center mb-8">
                                {/*begin::Symbol*/}
                                <div className="symbol symbol-circle symbol-40 symbol-light mr-3 flex-shrink-0">
                                    <div className="symbol-label">
                                        <span className="svg-icon svg-icon-lg svg-icon-gray-500">
                                            {/*begin::Svg Icon | path:/metronic/theme/html/demo7/dist/assets/media/svg/icons/Media/Equalizer.svg*/}
                                            <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                                <g stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
                                                    <rect x={0} y={0} width={24} height={24} />
                                                    <rect fill="#000000" opacity="0.3" x={13} y={4} width={3} height={16} rx="1.5" />
                                                    <rect fill="#000000" x={8} y={9} width={3} height={11} rx="1.5" />
                                                    <rect fill="#000000" x={18} y={11} width={3} height={9} rx="1.5" />
                                                    <rect fill="#000000" x={3} y={13} width={3} height={7} rx="1.5" />
                                                </g>
                                            </svg>
                                            {/*end::Svg Icon*/}
                                        </span>
                                    </div>
                                </div>
                                {/*end::Symbol*/}
                                {/*begin::Title*/}
                                <div>
                                    <a href="#" className="font-size-h6 text-dark-75 text-hover-primary font-weight-bolder">KEF FLT-120 Filtre Kahve Makinesi</a>
                                    <div className="font-size-sm text-muted font-weight-bold mt-1">En çok satılan ürün</div>
                                </div>
                                {/*end::Title*/}
                            </div>
                            {/*end::Widget Item*/}
                            {/*begin::Widget Item*/}
                            <div className="d-flex align-items-center mb-8">
                                {/*begin::Symbol*/}
                                <div className="symbol symbol-circle symbol-40 symbol-light mr-3 flex-shrink-0">
                                    <div className="symbol-label">
                                        <span className="svg-icon svg-icon-lg svg-icon-gray-500">
                                            {/*begin::Svg Icon | path:/metronic/theme/html/demo7/dist/assets/media/svg/icons/Shopping/Chart-pie.svg*/}
                                            <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                                <g stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
                                                    <rect x={0} y={0} width={24} height={24} />
                                                    <path d="M4.00246329,12.2004927 L13,14 L13,4.06189375 C16.9463116,4.55399184 20,7.92038235 20,12 C20,16.418278 16.418278,20 12,20 C7.64874861,20 4.10886412,16.5261253 4.00246329,12.2004927 Z" fill="#000000" opacity="0.3" />
                                                    <path d="M3.0603968,10.0120794 C3.54712466,6.05992157 6.91622084,3 11,3 L11,11.6 L3.0603968,10.0120794 Z" fill="#000000" />
                                                </g>
                                            </svg>
                                            {/*end::Svg Icon*/}
                                        </span>
                                    </div>
                                </div>
                                {/*end::Symbol*/}
                                {/*begin::Title*/}
                                <div>
                                    <a href="#" className="font-size-h6 text-dark-75 text-hover-primary font-weight-bolder">Dünya Kahveleri</a>
                                    <div className="font-size-sm text-muted font-weight-bold mt-1">En çok satış yapılan kategori</div>
                                </div>
                                {/*end::Title*/}
                            </div>
                            {/*end::Widget Item*/}
                            {/*begin::Widget Item*/}
                            <div className="d-flex align-items-center">
                                {/*begin::Symbol*/}
                                <div className="symbol symbol-circle symbol-40 symbol-light mr-3 flex-shrink-0">
                                    <div className="symbol-label">
                                        <span className="svg-icon svg-icon-lg svg-icon-gray-500">
                                            {/*begin::Svg Icon | path:/metronic/theme/html/demo7/dist/assets/media/svg/icons/Design/Layers.svg*/}
                                            <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                                <g stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
                                                    <polygon points="0 0 24 0 24 24 0 24" />
                                                    <path d="M12.9336061,16.072447 L19.36,10.9564761 L19.5181585,10.8312381 C20.1676248,10.3169571 20.2772143,9.3735535 19.7629333,8.72408713 C19.6917232,8.63415859 19.6104327,8.55269514 19.5206557,8.48129411 L12.9336854,3.24257445 C12.3871201,2.80788259 11.6128799,2.80788259 11.0663146,3.24257445 L4.47482784,8.48488609 C3.82645598,9.00054628 3.71887192,9.94418071 4.23453211,10.5925526 C4.30500305,10.6811601 4.38527899,10.7615046 4.47382636,10.8320511 L4.63,10.9564761 L11.0659024,16.0730648 C11.6126744,16.5077525 12.3871218,16.5074963 12.9336061,16.072447 Z" fill="#000000" fillRule="nonzero" />
                                                    <path d="M11.0563554,18.6706981 L5.33593024,14.122919 C4.94553994,13.8125559 4.37746707,13.8774308 4.06710397,14.2678211 C4.06471678,14.2708238 4.06234874,14.2738418 4.06,14.2768747 L4.06,14.2768747 C3.75257288,14.6738539 3.82516916,15.244888 4.22214834,15.5523151 C4.22358765,15.5534297 4.2250303,15.55454 4.22647627,15.555646 L11.0872776,20.8031356 C11.6250734,21.2144692 12.371757,21.2145375 12.909628,20.8033023 L19.7677785,15.559828 C20.1693192,15.2528257 20.2459576,14.6784381 19.9389553,14.2768974 C19.9376429,14.2751809 19.9363245,14.2734691 19.935,14.2717619 L19.935,14.2717619 C19.6266937,13.8743807 19.0546209,13.8021712 18.6572397,14.1104775 C18.654352,14.112718 18.6514778,14.1149757 18.6486172,14.1172508 L12.9235044,18.6705218 C12.377022,19.1051477 11.6029199,19.1052208 11.0563554,18.6706981 Z" fill="#000000" opacity="0.3" />
                                                </g>
                                            </svg>
                                            {/*end::Svg Icon*/}
                                        </span>
                                    </div>
                                </div>
                                {/*end::Symbol*/}
                                {/*begin::Title*/}
                                <div>
                                    <a href="#" className="font-size-h6 text-dark-75 text-hover-primary font-weight-bolder">Average Bestseller</a>
                                    <div className="font-size-sm text-muted font-weight-bold mt-1">Pitstop Email Marketing</div>
                                </div>
                                {/*end::Title*/}
                            </div>
                            {/*end::Widget Item*/}
                        </div>


                    </div>
                    {/*end::Body*/}
                </div>
                {/*end::Mixed Widget 18*/}
            </div>
            <div className="col-xl-5">
                {/*begin::Base Table Widget 3*/}
                <div className="card card-custom gutter-b card-stretch">
                    {/*begin::Header*/}
                    <div className="card-header border-0 pt-5">
                        <h3 className="card-title align-items-start flex-column">
                            <span className="card-label font-weight-bolder text-dark">Kategorilerin Özeti</span>
                            <span className="text-muted mt-3 font-weight-bold font-size-sm">More than 400+ new members</span>
                        </h3>
                        <div className="card-toolbar">
                            <a href="#" className="btn btn-light-primary btn-md py-2 font-weight-bolder dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">Create</a>
                            <div className="dropdown-menu dropdown-menu-sm dropdown-menu-right">
                                {/*begin::Navigation*/}
                                <ul className="navi navi-hover">
                                    <li className="navi-header pb-1">
                                        <span className="text-primary text-uppercase font-weight-bold font-size-sm">Add new:</span>
                                    </li>
                                    <li className="navi-item">
                                        <a href="#" className="navi-link">
                                            <span className="navi-icon">
                                                <i className="flaticon2-shopping-cart-1" />
                                            </span>
                                            <span className="navi-text">Order</span>
                                        </a>
                                    </li>
                                    <li className="navi-item">
                                        <a href="#" className="navi-link">
                                            <span className="navi-icon">
                                                <i className="flaticon2-calendar-8" />
                                            </span>
                                            <span className="navi-text">Event</span>
                                        </a>
                                    </li>
                                    <li className="navi-item">
                                        <a href="#" className="navi-link">
                                            <span className="navi-icon">
                                                <i className="flaticon2-graph-1" />
                                            </span>
                                            <span className="navi-text">Report</span>
                                        </a>
                                    </li>
                                    <li className="navi-item">
                                        <a href="#" className="navi-link">
                                            <span className="navi-icon">
                                                <i className="flaticon2-rocket-1" />
                                            </span>
                                            <span className="navi-text">Post</span>
                                        </a>
                                    </li>
                                    <li className="navi-item">
                                        <a href="#" className="navi-link">
                                            <span className="navi-icon">
                                                <i className="flaticon2-writing" />
                                            </span>
                                            <span className="navi-text">File</span>
                                        </a>
                                    </li>
                                </ul>
                                {/*end::Navigation*/}
                            </div>
                        </div>
                    </div>
                    {/*end::Header*/}
                    {/*begin::Body*/}
                    <div className="card-body pt-2 pb-0">
                        {/*begin::Table*/}
                        <div className="table-responsive">
                            <table className="table table-borderless table-vertical-center">
                                <thead>
                                    <tr>
                                        <th className="p-0" style={{ width: '50px' }} />
                                        <th className="p-0" style={{ minWidth: '150px' }} />
                                        <th className="p-0" style={{ minWidth: '100px' }} />
                                        <th className="p-0" style={{ minWidth: '40px' }} />
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td className="pl-0 py-5">
                                            <div className="symbol symbol-45 symbol-light-success mr-2">
                                                <span className="symbol-label">
                                                    <span className="svg-icon svg-icon-2x svg-icon-success">
                                                        {/*begin::Svg Icon | path:/metronic/theme/html/demo7/dist/assets/media/svg/icons/Shopping/Cart3.svg*/}
                                                        <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                                            <g stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
                                                                <rect x={0} y={0} width={24} height={24} />
                                                                <path d="M12,4.56204994 L7.76822128,9.6401844 C7.4146572,10.0644613 6.7840925,10.1217854 6.3598156,9.76822128 C5.9355387,9.4146572 5.87821464,8.7840925 6.23177872,8.3598156 L11.2317787,2.3598156 C11.6315738,1.88006147 12.3684262,1.88006147 12.7682213,2.3598156 L17.7682213,8.3598156 C18.1217854,8.7840925 18.0644613,9.4146572 17.6401844,9.76822128 C17.2159075,10.1217854 16.5853428,10.0644613 16.2317787,9.6401844 L12,4.56204994 Z" fill="#000000" fillRule="nonzero" opacity="0.3" />
                                                                <path d="M3.5,9 L20.5,9 C21.0522847,9 21.5,9.44771525 21.5,10 C21.5,10.132026 21.4738562,10.2627452 21.4230769,10.3846154 L17.7692308,19.1538462 C17.3034221,20.271787 16.2111026,21 15,21 L9,21 C7.78889745,21 6.6965779,20.271787 6.23076923,19.1538462 L2.57692308,10.3846154 C2.36450587,9.87481408 2.60558331,9.28934029 3.11538462,9.07692308 C3.23725479,9.02614384 3.36797398,9 3.5,9 Z M12,17 C13.1045695,17 14,16.1045695 14,15 C14,13.8954305 13.1045695,13 12,13 C10.8954305,13 10,13.8954305 10,15 C10,16.1045695 10.8954305,17 12,17 Z" fill="#000000" />
                                                            </g>
                                                        </svg>
                                                        {/*end::Svg Icon*/}
                                                    </span>
                                                </span>
                                            </div>
                                        </td>
                                        <td className="pl-0">
                                            <a href="#" className="text-dark font-weight-bolder text-hover-primary mb-1 font-size-lg">Top Authors</a>
                                        </td>
                                        <td className="text-right">
                                            <span className="text-muted font-weight-bold">4600 Users</span>
                                        </td>
                                        <td className="text-right pr-0">
                                            <span className="text-dark-75 font-weight-bolder font-size-lg">5.4MB</span>
                                        </td>
                                    </tr>

                                </tbody>
                            </table>
                        </div>
                        {/*end::table*/}
                    </div>
                    {/*begin::Body*/}
                </div>
                {/*end::Base Table Widget 3*/}
            </div>
            <div className="col-xl-3">
                <div className="card card-custom gutter-b">
                    {/*begin::Header*/}
                    <div className="card-header border-0 pt-5">
                        <h3 className="card-title align-items-start flex-column mb-5">
                            <span className="card-label font-weight-bolder text-dark mb-1">Recent Products</span>
                            <span className="text-muted mt-2 font-weight-bold font-size-sm">New Arrivals</span>
                        </h3>
                        <div className="card-toolbar">
                            <div className="dropdown dropdown-inline">
                                <a href="#" className="btn btn-clean btn-sm btn-icon" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                    <i className="ki ki-bold-more-hor" />
                                </a>
                                <div className="dropdown-menu dropdown-menu-md dropdown-menu-right">
                                    {/*begin::Navigation*/}
                                    <ul className="navi navi-hover">
                                        <li className="navi-header font-weight-bold py-4">
                                            <span className="font-size-lg">Choose Label:</span>
                                            <i className="flaticon2-information icon-md text-muted" data-toggle="tooltip" data-placement="right" title data-original-title="Click to learn more..." />
                                        </li>
                                        <li className="navi-separator mb-3 opacity-70" />
                                        <li className="navi-item">
                                            <a href="#" className="navi-link">
                                                <span className="navi-text">
                                                    <span className="label label-xl label-inline label-light-success">Customer</span>
                                                </span>
                                            </a>
                                        </li>
                                        <li className="navi-item">
                                            <a href="#" className="navi-link">
                                                <span className="navi-text">
                                                    <span className="label label-xl label-inline label-light-danger">Partner</span>
                                                </span>
                                            </a>
                                        </li>
                                        <li className="navi-item">
                                            <a href="#" className="navi-link">
                                                <span className="navi-text">
                                                    <span className="label label-xl label-inline label-light-warning">Suplier</span>
                                                </span>
                                            </a>
                                        </li>
                                        <li className="navi-item">
                                            <a href="#" className="navi-link">
                                                <span className="navi-text">
                                                    <span className="label label-xl label-inline label-light-primary">Member</span>
                                                </span>
                                            </a>
                                        </li>
                                        <li className="navi-item">
                                            <a href="#" className="navi-link">
                                                <span className="navi-text">
                                                    <span className="label label-xl label-inline label-light-dark">Staff</span>
                                                </span>
                                            </a>
                                        </li>
                                        <li className="navi-separator mt-3 opacity-70" />
                                        <li className="navi-footer py-4">
                                            <a className="btn btn-clean font-weight-bold btn-sm" href="#">
                                                <i className="ki ki-plus icon-sm" />Add new</a>
                                        </li>
                                    </ul>
                                    {/*end::Navigation*/}
                                </div>
                            </div>
                        </div>
                    </div>
                    {/*end::Header*/}
                    {/*begin::Body*/}
                    <div className="card-body pt-2">
                        {/*begin::Item*/}
                        <div className="d-flex mb-8">
                            {/*begin::Symbol*/}
                            <div className="symbol symbol-50 symbol-2by3 flex-shrink-0 mr-4">
                                <div className="d-flex flex-column">
                                    <div className="symbol-label mb-3" style={{ backgroundImage: 'url("/metronic/theme/html/demo7/dist/assets/media/stock-600x400/img-23.jpg")' }} />
                                    <a href="#" className="btn btn-light-primary font-weight-bolder py-2 font-size-sm">Edit</a>
                                </div>
                            </div>
                            {/*end::Symbol*/}
                            {/*begin::Title*/}
                            <div className="d-flex flex-column flex-grow-1 my-lg-0 my-2 pr-3">
                                <a href="#" className="text-dark-75 font-weight-bolder text-hover-primary font-size-lg mb-2">Darius the Great</a>
                                <span className="text-muted font-weight-bold font-size-sm mb-3">All it takes tank credibility is one glaring error</span>
                                <span className="text-muted font-weight-bold font-size-lg">Price:
                                    <span className="text-dark-75 font-weight-bolder">99.00$</span></span>
                            </div>
                            {/*end::Title*/}
                        </div>
                        {/*end::Item*/}
                        {/*begin::Item*/}
                        <div className="d-flex">
                            {/*begin::Symbol*/}
                            <div className="symbol symbol-50 symbol-2by3 flex-shrink-0 mr-4">
                                <div className="d-flex flex-column">
                                    <div className="symbol-label mb-3" style={{ backgroundImage: 'url("/metronic/theme/html/demo7/dist/assets/media/stock-600x400/img-25.jpg")' }} />
                                    <a href="#" className="btn btn-light-primary font-weight-bolder py-2 font-size-sm">Edit</a>
                                </div>
                            </div>
                            {/*end::Symbol*/}
                            {/*begin::Title*/}
                            <div className="d-flex flex-column flex-grow-1 my-lg-0 my-2 pr-3">
                                <a href="#" className="text-dark-75 font-weight-bolder text-hover-primary font-size-lg mb-2">Nike Airmax</a>
                                <span className="text-muted font-weight-bold font-size-sm mb-3">All it takes tank credibility is one glaring error</span>
                                <span className="text-muted font-weight-bold font-size-lg">Price:
                                    <span className="text-dark-75 font-weight-bolder">99.00$</span></span>
                            </div>
                            {/*end::Title*/}
                        </div>
                        {/*end::Item*/}
                    </div>
                    {/*end::Body*/}
                </div>

            </div>

            <div className="col-md-12">
                
                    <div className="card card-custom card-stretch gutter-b">
                        {/*begin::Header*/}
                        <div className="card-header border-0 pt-6 mb-2">
                            <h3 className="card-title align-items-start flex-column">
                                <span className="card-label font-weight-bold font-size-h4 text-dark-75 mb-3">Bekleyen Siparişler</span>
                                <span className="text-muted font-weight-bold font-size-sm">More than 400+ new members</span>
                            </h3>
                            <div className="card-toolbar">
                                <div className="dropdown dropdown-inline" data-toggle="tooltip" title data-placement="left" data-original-title="Quick actions">
                                    <a href="#" className="btn btn-light-danger btn-sm font-weight-normal font-size-sm dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">Dashboard</a>
                                    <div className="dropdown-menu dropdown-menu-md dropdown-menu-right">
                                        {/*begin::Navigation*/}
                                        <ul className="navi navi-hover py-5">
                                            <li className="navi-item">
                                                <a href="#" className="navi-link">
                                                    <span className="navi-icon">
                                                        <i className="flaticon2-drop" />
                                                    </span>
                                                    <span className="navi-text">New Group</span>
                                                </a>
                                            </li>
                                            <li className="navi-item">
                                                <a href="#" className="navi-link">
                                                    <span className="navi-icon">
                                                        <i className="flaticon2-list-3" />
                                                    </span>
                                                    <span className="navi-text">Contacts</span>
                                                </a>
                                            </li>
                                            <li className="navi-item">
                                                <a href="#" className="navi-link">
                                                    <span className="navi-icon">
                                                        <i className="flaticon2-rocket-1" />
                                                    </span>
                                                    <span className="navi-text">Groups</span>
                                                    <span className="navi-link-badge">
                                                        <span className="label label-light-primary label-inline font-weight-bold">new</span>
                                                    </span>
                                                </a>
                                            </li>
                                            <li className="navi-item">
                                                <a href="#" className="navi-link">
                                                    <span className="navi-icon">
                                                        <i className="flaticon2-bell-2" />
                                                    </span>
                                                    <span className="navi-text">Calls</span>
                                                </a>
                                            </li>
                                            <li className="navi-item">
                                                <a href="#" className="navi-link">
                                                    <span className="navi-icon">
                                                        <i className="flaticon2-gear" />
                                                    </span>
                                                    <span className="navi-text">Settings</span>
                                                </a>
                                            </li>
                                            <li className="navi-separator my-3" />
                                            <li className="navi-item">
                                                <a href="#" className="navi-link">
                                                    <span className="navi-icon">
                                                        <i className="flaticon2-magnifier-tool" />
                                                    </span>
                                                    <span className="navi-text">Help</span>
                                                </a>
                                            </li>
                                            <li className="navi-item">
                                                <a href="#" className="navi-link">
                                                    <span className="navi-icon">
                                                        <i className="flaticon2-bell-2" />
                                                    </span>
                                                    <span className="navi-text">Privacy</span>
                                                    <span className="navi-link-badge">
                                                        <span className="label label-light-danger label-rounded font-weight-bold">5</span>
                                                    </span>
                                                </a>
                                            </li>
                                        </ul>
                                        {/*end::Navigation*/}
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/*end::Header*/}
                        {/*begin::Body*/}
                        <div className="card-body pt-2">
                            {/*begin::Table*/}
                            <div className="table-responsive">
                                <table className="table table-borderless mb-0">
                                    <tbody>
                                        {/*begin::Item*/}
                                        <tr>
                                            {/*begin::Icon*/}
                                            <td className="align-middle w-50px pl-0 pr-2 pb-6">
                                                {/*begin::Symbol*/}
                                                <div className="symbol symbol-50 symbol-light-success">
                                                    <div className="symbol-label" style={{ backgroundImage: 'url("/metronic/theme/html/demo7/dist/assets/media/stock-600x400/img-26.jpg")' }} />
                                                </div>
                                                {/*end::Symbol*/}
                                            </td>
                                            {/*end::Icon*/}
                                            {/*begin::Content*/}
                                            <td className="align-middle pb-6">
                                                <div className="font-size-lg font-weight-bolder text-dark-75 mb-1">Special Meal</div>
                                                <div className="font-weight-bold text-muted">Quona Rice</div>
                                            </td>
                                            <td className="font-weight-bold text-muted align-middle text-right pb-6">
                                                <span className="svg-icon">
                                                    {/*begin::Svg Icon | path:/metronic/theme/html/demo7/dist/assets/media/svg/icons/Navigation/Close.svg*/}
                                                    <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                                        <g stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
                                                            <g transform="translate(12.000000, 12.000000) rotate(-45.000000) translate(-12.000000, -12.000000) translate(4.000000, 4.000000)" fill="#000000">
                                                                <rect x={0} y={7} width={16} height={2} rx={1} />
                                                                <rect opacity="0.3" transform="translate(8.000000, 8.000000) rotate(-270.000000) translate(-8.000000, -8.000000)" x={0} y={7} width={16} height={2} rx={1} />
                                                            </g>
                                                        </g>
                                                    </svg>
                                                    {/*end::Svg Icon*/}
                                                </span>
                                                <span className="text-success font-size-h5 font-weight-bolder ml-1">5</span>
                                            </td>
                                            <td className="text-right align-middle pb-6">
                                                <div className="font-weight-bold text-muted mb-1">To Pay</div>
                                                <div className="font-size-lg font-weight-bolder text-dark-75">$600.00</div>
                                            </td>
                                            {/*end::Content*/}
                                        </tr>
                                        {/*end::Item*/}
                                        {/*begin::Item*/}
                                        <tr>
                                            {/*begin::Icon*/}
                                            <td className="align-middle w-50px pl-0 pr-2 pb-6">
                                                {/*begin::Symbol*/}
                                                <div className="symbol symbol-50 symbol-light-success">
                                                    <div className="symbol-label" style={{ backgroundImage: 'url("/metronic/theme/html/demo7/dist/assets/media/stock-600x400/img-3.jpg")' }} />
                                                </div>
                                                {/*end::Symbol*/}
                                            </td>
                                            {/*end::Icon*/}
                                            {/*begin::Content*/}
                                            <td className="align-middle pb-6">
                                                <div className="font-size-lg font-weight-bolder text-dark-75 mb-1">Orange Wholesale</div>
                                                <div className="font-weight-bold text-muted">Blue Orange</div>
                                            </td>
                                            <td className="font-weight-bold text-muted align-middle text-right pb-6">
                                                <span className="svg-icon">
                                                    {/*begin::Svg Icon | path:/metronic/theme/html/demo7/dist/assets/media/svg/icons/Navigation/Close.svg*/}
                                                    <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                                        <g stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
                                                            <g transform="translate(12.000000, 12.000000) rotate(-45.000000) translate(-12.000000, -12.000000) translate(4.000000, 4.000000)" fill="#000000">
                                                                <rect x={0} y={7} width={16} height={2} rx={1} />
                                                                <rect opacity="0.3" transform="translate(8.000000, 8.000000) rotate(-270.000000) translate(-8.000000, -8.000000)" x={0} y={7} width={16} height={2} rx={1} />
                                                            </g>
                                                        </g>
                                                    </svg>
                                                    {/*end::Svg Icon*/}
                                                </span>
                                                <span className="text-success font-size-h5 font-weight-bolder ml-1">200</span>
                                            </td>
                                            <td className="text-right align-middle pb-6">
                                                <div className="font-weight-bold text-muted mb-1">To Pay</div>
                                                <div className="font-size-lg font-weight-bolder text-dark-75">$6400.00</div>
                                            </td>
                                            {/*end::Content*/}
                                        </tr>
                                        {/*end::Item*/}
                                        {/*begin::Item*/}
                                        <tr>
                                            {/*begin::Icon*/}
                                            <td className="align-middle w-50px pl-0 pr-2">
                                                {/*begin::Symbol*/}
                                                <div className="symbol symbol-50 symbol-light-success">
                                                    <div className="symbol-label" style={{ backgroundImage: 'url("/metronic/theme/html/demo7/dist/assets/media/stock-600x400/img-5.jpg")' }} />
                                                </div>
                                                {/*end::Symbol*/}
                                            </td>
                                            {/*end::Icon*/}
                                            {/*begin::Content*/}
                                            <td className="align-middle">
                                                <div className="font-size-lg font-weight-bolder text-dark-75 mb-1">Avocado Box</div>
                                                <div className="font-weight-bold text-muted">From Spain</div>
                                            </td>
                                            <td className="font-weight-bold text-muted align-middle text-right">
                                                <span className="svg-icon">
                                                    {/*begin::Svg Icon | path:/metronic/theme/html/demo7/dist/assets/media/svg/icons/Navigation/Close.svg*/}
                                                    <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                                        <g stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
                                                            <g transform="translate(12.000000, 12.000000) rotate(-45.000000) translate(-12.000000, -12.000000) translate(4.000000, 4.000000)" fill="#000000">
                                                                <rect x={0} y={7} width={16} height={2} rx={1} />
                                                                <rect opacity="0.3" transform="translate(8.000000, 8.000000) rotate(-270.000000) translate(-8.000000, -8.000000)" x={0} y={7} width={16} height={2} rx={1} />
                                                            </g>
                                                        </g>
                                                    </svg>
                                                    {/*end::Svg Icon*/}
                                                </span>
                                                <span className="text-success font-size-h5 font-weight-bolder ml-1">2</span>
                                            </td>
                                            <td className="text-right align-middle">
                                                <div className="font-weight-bold text-muted mb-1">To Pay</div>
                                                <div className="font-size-lg font-weight-bolder text-dark-75">$800.00</div>
                                            </td>
                                            {/*end::Content*/}
                                        </tr>
                                        {/*end::Item*/}
                                    </tbody>
                                </table>
                            </div>
                            {/*end::Table*/}
                        </div>
                        {/*end::Body*/}
                    </div>
                    

            </div>

        </div>
    </>
}