import MyHelper from '../../helper/MyHelper';

const axios = require('axios');
export default function DBProduct() {
    function GetAllProduct(startDate,endDate) {
        return new Promise((ok, fail) => {
            axios.post(MyHelper().URLwithService(), {
                command: 'product_all',
                startDate: startDate,
                endDate: endDate,
            })
                .then(function (response) {
                    // console.log(response);

                    ok(response.data);
                })
                .catch(function (error) {
                    console.log(error);
                });
        })
    }

    function GetProductDetail(productID) {
        return new Promise((ok, fail) => {
            axios.post(MyHelper().URLwithService(), {
                command: 'product_detail',
                productID: productID
            })
                .then(function (response) {
                    ok(response.data);
                })
                .catch(function (error) {
                    console.log(error);
                });
        })
    }
    function Insert(tittle, description, images, groupOne, groupTwo, category, isServices, servicesPrice) {
        return new Promise((ok, fail) => {
            axios.post(MyHelper().URLwithService(), {
                command: 'product_insert',
                tittle: tittle,
                description: description,
                images: images,
                stock: [],
                groupOne: groupOne,
                groupTwo: groupTwo,
                categoryID: category,
                isServices: isServices,
                servicesPrice: servicesPrice
            })
                .then(function (response) {
                    ok(response.data);
                    console.log("DB");
                    console.log(isServices);
                    console.log(servicesPrice);
                })
                .catch(function (error) {
                    console.log(error);
                });
        })
    }
    function InsertStock(firstOptionGroup, secondOptionGroup, buyPrice, sellPrice, stock, date, productID) {
        return new Promise((ok, fail) => {
            axios.post(MyHelper().URLwithService(), {
                command: 'product_stock_insert',
                firstOptionGroup: firstOptionGroup,
                secondOptionGroup: secondOptionGroup,
                buyPrice: buyPrice,
                sellPrice: sellPrice,
                stock: stock,
                date: date,
                productID: productID
            })
                .then(function (response) {
                    console.log(response)
                    ok(response.data);
                })
                .catch(function (error) {
                    console.log(error);
                });
        })
    }
    function ListStock(productID) {
        return new Promise((ok, fail) => {
            axios.post(MyHelper().URLwithService(), {
                command: 'product_stock_list',
                productID: productID
            })
                .then(function (response) {
                    ok(response.data);
                })
                .catch(function (error) {
                    console.log(error);
                });
        })
    }
    function RemoveStock(stockID) {
        console.log(stockID)
        return new Promise((ok, fail) => {
            axios.post(MyHelper().URLwithService(), {
                command: 'product_stock_remove',
                stockID: stockID
            })
                .then(function (response) {
                    console.log(response);
                    ok(response.data);
                })
                .catch(function (error) {
                    console.log(error);
                });
        })
    }

    function ImageInsert(productID, imageURL) {
        return new Promise((ok, fail) => {
            axios.post(MyHelper().URLwithService(), {
                command: 'product_image_insert',
                productID: productID,
                imageURL: imageURL,
            })
                .then(function (response) {
                    ok(response.data);
                })
                .catch(function (error) {
                    console.log(error);
                });
        })
    }

    function ImageRemove(imageID) {
        return new Promise((ok, fail) => {
            axios.post(MyHelper().URLwithService(), {
                command: 'product_image_delete',
                imageID: imageID,
            })
                .then(function (response) {
                    console.log(response)
                    ok(response.data);
                })
                .catch(function (error) {
                    console.log(error);
                });
        })
    }

    function Remove(id) {
        return new Promise((ok, fail) => {
            axios.post(MyHelper().URLwithService(), {
                command: 'product_remove',
                productID: id
            })
                .then(function (response) {
                    ok(response.data);
                })
                .catch(function (error) {
                    console.log(error);
                });
        })
    }
    function ProductInformationUpdate(id, tittle, description, category, isServices, servicesPrice) {
        return new Promise((ok, fail) => {
            axios.post(MyHelper().URLwithService(), {
                command: 'product_information_update',
                productID: id,
                productTittle: tittle,
                productDescription: description,
                productCategory: category,
                isServices: isServices,
                servicesPrice: servicesPrice

            })
                .then(function (response) {
                    ok(response.data);
                })
                .catch(function (error) {
                    console.log(error);
                });
        })
    }

    function GetGroupProduct() {
        return new Promise((ok, fail) => {
            axios.post(MyHelper().URLwithService(), {
                command: 'product_option_group_list',
            })
                .then(function (response) {
                    ok(response.data);
                })
                .catch(function (error) {
                    console.log(error);
                });
        })
    }
    const headers = {
        'Content-Type': 'text/plain'
    };

    function Pay() {
        return new Promise((ok, fail) => {
            axios.post('https://aeo.ptt.gov.tr/api/Payment', {
                clientId: '1000021414',
                apiUser: '3031517',
                Rnd: "12412352135",
                timeSpan: '20210101121212',
                Hash: 'asdf',
                callbackUrl: 'https://ekmekk.app',
                //orderId: 'product_option_group_list',
                Amount: 1522,
                Currency: 949,
                //installmentCount: 0,
            },
                { headers })
                .then(function (response) {
                    ok(response.data);
                })
                .catch(function (error) {
                    console.log(error);
                });
        })
    }
    return {
        GetAllProduct, GetProductDetail, Insert, GetGroupProduct, Pay, Remove, ProductInformationUpdate, InsertStock, ListStock, RemoveStock, ImageInsert, ImageRemove
    }
}