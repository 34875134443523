import logo from './logo.svg';
import './Cactus/css/style.css';
import Header from './Cactus/component/header';
import Footer from './Cactus/component/footer';
import Body from './Cactus/component/body';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link
} from "react-router-dom";
import 'sweetalert2/dist/sweetalert2.min.css';

function App() {
  return (
    <div>


      <div className="d-flex flex-column flex-root">
        {/*begin::Page*/}
        <div className="d-flex flex-row flex-column-fluid page">
          <Router>
          <Switch>
        
            <div className="d-flex flex-column flex-row-fluid wrapper" id="kt_wrapper">
              <Header />
              <div className="content d-flex flex-column flex-column-fluid">
                <div className="d-flex flex-column-fluid">
                  <div className="container">
                    
                    <Body />
                  </div>

                </div>

              </div>


              <Footer />
            </div>
            </Switch>
          </Router>
          {/*end::Wrapper*/}
        </div>
        {/*end::Page*/}
      </div>




    </div>

  );
}

export default App;
