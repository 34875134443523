import { useHistory, useParams } from "react-router-dom";
import MediaPicker from "../../../Cactus/component/media_picker";

import React, { useState, useEffect, useRef } from 'react'
import { Ckeditor } from '../../component/Ckeditor'
import "react-datepicker/dist/react-datepicker.css";

import ProductStockInsert from "../../component/product_stock_insert";
import { faCamera, faPlusCircle, faRuler, faTimes, faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Validator, { ValidationController } from '../../../Cactus/helper/Validator';
import AlertEkmekk from "../../helper/alert";
import DatePicker from "react-datepicker";

import MyHelper from "../../helper/MyHelper";
import DataCenter from "../../Core/Database/DataCenter";
import { DMProductGroup } from "../../Core/Database/DataModel/DataModel";


export default function ProductUpdate() {

    const productGroup: DMProductGroup[] = [];

    const [productDescription, setProductDescription] = useState()

    const [images, setImages] = useState([]);
    const [category, setCategory] = useState(productGroup)
    const [selectedBlog, setSelectedBlog] = useState();

    const [categoryList, setCategoryList] = useState([])

    const [productData, setProductData] = useState()
    const [product, setProduct] = useState()
    const [stock, setStock] = useState([])

    const [servicePrice, setServicePrice] = useState("")

    let { id } = useParams();

    useEffect(() => {

        // ADMİN TARAFI İÇİN ÜRÜN DETAYI LİNKİ LAZIM

        DataCenter().Product.Category.List().then(r => {
            console.log(r)
            setCategoryList(r);
        })


        DataCenter().Product.Stock.List(id).then(r => {
            setStock(r);
        });


        DataCenter().Product.Group.List().then((r) => {
            setCategory(r);
            GetProductDetail();
        })


    }, [])

    function GetProductDetail() {

        
        DataCenter().Product.Detail(id).then((r) => {
            console.log(r)


            if (r.servicePrice != undefined && r.servicePrice.ek_service_price > 0) {
                setServicePrice(r.servicePrice.ek_service_price)
            } else {
                if (r.items[0].ek_option_group_id == null && r.items[1].ek_option_group_id == null) {
                    setProductType(1);
                    if (r.priceList.length > 0) {
                        setInputSingleSellPrice(r.priceList[0].ek_sell_price);
                        setInputSingleBuyPrice(r.priceList[0].ek_buy_price);
                        setInputSingleStockPrice(r.priceList[0].ek_stock);
                    }


                }
                else if (r.items[0].ek_option_group_id != null && r.items[1].ek_option_group_id == null) {
                    setProductType(3);
                }
                else {
                    setProductType(2);

                }
            }



            console.log(r);
            setEditorText(r.ek_product_description)
            setProductData(r);
            setProductCategory(r.ek_category_id);
        })


    }



    const [editorG, setEditorG] = useState();
    const [groupList, setGroupList] = useState([]);
    const [showGroup, setShowGroup] = useState([]);
    const [showGroup2, setShowGroup2] = useState([]);
    const [productType, setProductType] = useState(-1);
    const [productCategory, setProductCategory] = useState(-1);
    const inputTittle = useRef(null);
    const inputServicePrice = useRef(null)
    const [inputSingleBuyPrice, setInputSingleBuyPrice] = useState();
    const [inputSingleSellPrice, setInputSingleSellPrice] = useState();
    const [inputSingleStockPrice, setInputSingleStockPrice] = useState();
    const [editorText, setEditorText] = useState("")

    const [optionGroupOne, setOptionGroupOne] = useState([]);

    const [showGroupIndex, setShowGroupIndex] = useState(0);


    useEffect(() => {


        if (productData != undefined && servicePrice == "") {

            SelectOption(0, productData.items[0].ek_option_group_id)
            SelectOption(1, productData.items[1].ek_option_group_id)

        }

    }, [productData])

    function SelectedImages(images2) {

        setImages(images2)
        // setImages(images2);

    }
    function UpdateImages() {
        var ik = 0;
        images.map(item => {
            DataCenter().Product.Images.Insert(id, item.url).then(r => {
                ik++;
                if (ik == images.length) {
                    DataCenter().Product.Detail(id).then(r => {
                        productData.images = r.images;
                        setProductData(JSON.parse(JSON.stringify(productData)));
                        setImages([]);
                    });
                }
            });
        })
    }
    var history = useHistory();

    function UpdateProduct() {

        for (let i = 0; i < showGroup.length; i++) {

            console.log(showGroup[i].item1.ek_option_group_id, showGroup[i].item2.ek_option_group_id, showGroup[i].buyPrice, showGroup[i].sellPrice, showGroup[i].stock, showGroup[i].date, id);

            if (showGroup[i].item1.ek_option_group_id == undefined) {
                DataCenter().Product.Stock.Insert(-1, -1, showGroup[i].buyPrice, showGroup[i].sellPrice, showGroup[i].stock, showGroup[i].date, id)

            }
            else if (showGroup[i].item2.ek_option_group_id == undefined) {
                DataCenter().Product.Stock.Insert(showGroup[i].item1.ek_option_group_id, -1, showGroup[i].buyPrice, showGroup[i].sellPrice, showGroup[i].stock, showGroup[i].date, id)

            }
            else {
                DataCenter().Product.Stock.Insert(showGroup[i].item1.ek_option_group_id, showGroup[i].item2.ek_option_group_id, showGroup[i].buyPrice, showGroup[i].sellPrice, showGroup[i].stock, showGroup[i].date, id)

            }


        }

        DataCenter().Product.Update(id, inputTittle.current.value, productDescription, productCategory).then((r) => {
            console.log(r);
            MyHelper().AlertSuccess();

            history.push("/admin/products")
        })

    }

    function SelectOption(index, value) {

        optionGroupOne[index] = value;

        setOptionGroupOne([...optionGroupOne]);
        if (value == -1) {

            groupList.splice(index, groupList.length);
            setGroupList([...groupList])
        }
        else {

            console.log()

            category.map((item) => {

                if (item.ek_option_group_id == value) {
                    groupList[index] = item;
                }
            })


            setGroupList([...groupList])

        }


    }
    function ImagesBoxData({ item, imageID }) {
        return <div className="image-input image-input-outline mr-6 mt-6" id="kt_profile_avatar" style={{ backgroundImage: 'url(/metronic/theme/html/demo7/dist/assets/media/users/blank.png)', }}>
            <div className="image-input-wrapper" style={{ backgroundImage: 'url(' + item + ')', backgroundSize: "cover", backgroundPosition: "center" }} />
            <label onClick={(e) => {

                e.stopPropagation();

                MyHelper().AlertSure((e) => {

                    if (e) {

                        DataCenter().Product.Images.Remove(imageID).then(r => {
                            if (r.response) {

                                GetProductDetail();
                            }
                        });


                    }


                }, "fotoğraf silinecek")


                for (let i = 0; i < images.length; i++) {

                    if (item == images[i].url) {
                        images.splice(i, 1);
                    }

                }
                setImages([...images])
                // setImages([])
            }} className="btn btn-xs btn-icon btn-circle btn-white btn-hover-text-primary btn-shadow" data-action="change" data-toggle="tooltip" title data-original-title="Change avatar">
                <FontAwesomeIcon icon={faTimes} />
            </label>
        </div>


    }
    function ImagesBox({ item }) {
        return <div className="image-input image-input-outline mr-6 mt-6" id="kt_profile_avatar" style={{ backgroundImage: 'url(/metronic/theme/html/demo7/dist/assets/media/users/blank.png)' }}>
            <div className="image-input-wrapper" style={{ backgroundImage: 'url(' + item + ')', backgroundSize: "cover", backgroundImage: 'url(' + item + ')' }} />
            <label onClick={(e) => {

                e.stopPropagation();

                for (let i = 0; i < images.length; i++) {

                    if (item == images[i].url) {
                        images.splice(i, 1);
                    }

                }
                setImages([...images])
                // setImages([])
            }} className="btn btn-xs btn-icon btn-circle btn-white btn-hover-text-primary btn-shadow" data-action="change" data-toggle="tooltip" title data-original-title="Change avatar">
                <FontAwesomeIcon icon={faTimes} />
            </label>
        </div>

        return <div className="col-md-2 my-4 ">
            <div className="image-input image-input-outline" id="kt_profile_avatar" style={{ backgroundImage: 'url(/metronic/theme/html/demo7/dist/assets/media/users/blank.png)' }}>
                <div className="image-input-wrapper" style={{ backgroundImage: 'url(' + item + ')', backgroundSize: "contain", backgroundRepeat: "no-repeat", backgroundPosition: "center" }}>
                    <div onClick={(e) => {

                        e.stopPropagation();

                        for (let i = 0; i < images.length; i++) {

                            if (item == images[i].url) {
                                images.splice(i, 1);
                            }

                        }
                        setImages([...images])
                        // setImages([])
                    }} id="x">x</div>
                </div>
            </div>
        </div>
    }

    function InsertStockPool(item) {


        DataCenter().Product.Stock.Insert(item[0]["item1"].ek_option_group_id == undefined ? -1 : item[0]["item1"].ek_option_group_id, item[0]["item2"].ek_option_group_id == undefined ? -1 : item[0]["item2"].ek_option_group_id, item[0].buyPrice, item[0].sellPrice, item[0].stock, "", id).then(r => {
            DataCenter().Product.Stock.List(id).then(rr => {
                setStock(rr);
            })
        });
        return false;

    }

    function GetGroupName(id) {
        var selected;

        category.map(item => {
            if (item.ek_option_group_id == id) {
                selected = item;
            }
            else
            {
                item.ek_children.map(it=>{
                    if (it.ek_option_group_id == id) {
                        selected = it;
                    }
                })
            }
        })
        if (selected != undefined) {
            return selected.ek_tittle;
        }

    }




    function StockItem({ item }) {
        // console.log(item)
        return <div className="row mt-4">

            {/* <div className="col-md-3">
            <div className="text px-2 mb-2"><b>{item.item1.ek_tittle + "-" + item.item2.ek_tittle}</b></div>

        </div> */}
            {
                item.item1.length == 0 && item.item2.length == 0 ? <div className="col-md-3">
                    <div className="text px-2 mb-2"><b>Ürün bilgileri</b></div>
                </div> : <div className="col-md-3">
                    <div className="text px-2 mb-2"><b>{item.item1.length != 0 ? item.item1.ek_tittle : ""}{item.item2.length != 0 ? " - " + item.item2.ek_tittle : ""}</b></div>

                </div>
            }

            <div className="col-md-2">
                <input onChange={(e) => { ChangeValue("buy", item, e.target.value) }} data-validation-rule="notNull|onlyNumber" type="text" name="bash" className="form-control mt-0" defaultValue={item.buyPrice} />
            </div>
            <div className="col-md-2">
                <input onChange={(e) => { ChangeValue("sell", item, e.target.value) }} data-validation-rule="notNull|onlyNumber" type="text" name="bash" className="form-control mt-0" defaultValue={item.sellPrice} />
            </div>
            <div className="col-md-2">
                <input onChange={(e) => { ChangeValue("stock", item, e.target.value) }} data-validation-rule="notNull|onlyNumber" type="text" name="bash" className="form-control mt-0" defaultValue={item.stock} />
            </div>
            <div className="col-md-2">
                <DatePicker dateFormat="dd/MM/yyyy" className="form-control mt-0" selected={item.date} onChange={(date) => ChangeValue("date", item, date)} />
            </div>
            <div className="col-md-1" onClick={() => {
                for (let i = 0; i < showGroup.length; i++) {

                    if (showGroup[i].index == item.index) {
                        showGroup.splice(i, 1);

                        setShowGroup([...showGroup]);
                    }

                }
            }}>
                <FontAwesomeIcon icon={faTrash} />
            </div>
            <hr />


        </div>
    }



    function ChangeValue(type, item, data) {

        // console.log(data);
        if (type == "buy") {
            showGroup.map((i, index) => {
                if (i == item) {
                    showGroup[index].buyPrice = data.replace(",", ".");
                }
            })
        }
        else if (type == "sell") {
            showGroup.map((i, index) => {
                if (i == item) {
                    showGroup[index].sellPrice = data.replace(",", ".");
                }
            })
        }
        else if (type == "stock") {
            showGroup.map((i, index) => {
                if (i == item) {
                    showGroup[index].stock = data.replace(",", ".");
                }
            })
        }
        else if (type == "date") {
            showGroup.map((i, index) => {
                if (i == item) {
                    showGroup[index].date = data;
                    setShowGroup([...showGroup]);
                }
            })
        }

    }

    function ChangeValue2(type, item, data) {


        if (type == "buy") {
            showGroup2.map((i, index) => {
                if (i == item) {
                    showGroup2[index].buyPrice = data.replace(",", ".");
                }
            })
        }
        else if (type == "sell") {
            showGroup2.map((i, index) => {
                if (i == item) {
                    showGroup2[index].sellPrice = data.replace(",", ".");
                }
            })
        }
        else if (type == "stock") {
            showGroup2.map((i, index) => {
                if (i == item) {
                    showGroup2[index].stock = data.replace(",", ".");
                }
            })
        }
        else if (type == "date") {
            showGroup2.map((i, index) => {
                if (i == item) {
                    showGroup2[index].date = data;
                    setShowGroup2([...showGroup2]);
                }
            })
        }

    }


    return <>
        <div className="row">


            <div className="col-md-6">
                <div className="card card-custom gutter-b example example-compact">
                    <div className="card-header">
                        <h3 className="card-title">Ürün Bilgileri</h3>
                        <div className="card-toolbar">

                        </div>
                    </div>
                    {/*begin::Form*/}
                    <form>
                        <div className="card-body">

                            <div className="form-group">
                                <label>Ürün Adı
                                    <span className="text-danger">*</span></label>
                                <input ref={inputTittle} data-validation-rule="notNull" defaultValue={productData && productData.ek_product_name} type="email" className="form-control" placeholder="Ürün Adı" />
                                <span className="form-text text-muted">Bu alanı boş bırakamazsınız</span>
                            </div>
                            <div className="form-group">
                                <label htmlFor="exampleInputPassword1">Ürün Açıklaması
                                    <span className="text-danger">*</span></label>
                                {editorText && <Ckeditor onChange={(e) => setProductDescription(e)} editorText={editorText} />}


                            </div>





                        </div>
                        <div className="card-footer">
                            <button onClick={UpdateProduct} type="reset" className="btn btn-primary mr-2">Ürünü Güncelle</button>
                        </div>
                    </form>
                    {/*end::Form*/}
                </div>

            </div>

            <div className="col-md-6">
                <div className="card card-custom gutter-b example example-compact">
                    <div className="card-header">
                        <h3 className="card-title">Kategori Bilgileri</h3>
                        <div className="card-toolbar">

                        </div>
                    </div>
                    {/*begin::Form*/}
                    <form>
                        <div className="card-body">

                            <div className="row">

                                <div className="col-md-12">
                                    <div className="alert alert-custom alert-default" role="alert">
                                        <div className="alert-icon">
                                            <span className="svg-icon svg-icon-primary svg-icon-xl">
                                                {/*begin::Svg Icon | path:/metronic/theme/html/demo7/dist/assets/media/svg/icons/Tools/Compass.svg*/}
                                                <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                                    <g stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
                                                        <rect x={0} y={0} width={24} height={24} />
                                                        <path d="M7.07744993,12.3040451 C7.72444571,13.0716094 8.54044565,13.6920474 9.46808594,14.1079953 L5,23 L4.5,18 L7.07744993,12.3040451 Z M14.5865511,14.2597864 C15.5319561,13.9019016 16.375416,13.3366121 17.0614026,12.6194459 L19.5,18 L19,23 L14.5865511,14.2597864 Z M12,3.55271368e-14 C12.8284271,3.53749572e-14 13.5,0.671572875 13.5,1.5 L13.5,4 L10.5,4 L10.5,1.5 C10.5,0.671572875 11.1715729,3.56793164e-14 12,3.55271368e-14 Z" fill="#000000" opacity="0.3" />
                                                        <path d="M12,10 C13.1045695,10 14,9.1045695 14,8 C14,6.8954305 13.1045695,6 12,6 C10.8954305,6 10,6.8954305 10,8 C10,9.1045695 10.8954305,10 12,10 Z M12,13 C9.23857625,13 7,10.7614237 7,8 C7,5.23857625 9.23857625,3 12,3 C14.7614237,3 17,5.23857625 17,8 C17,10.7614237 14.7614237,13 12,13 Z" fill="#000000" fillRule="nonzero" />
                                                    </g>
                                                </svg>
                                                {/*end::Svg Icon*/}
                                            </span>
                                        </div>
                                        <div className="alert-text">Ürüne ait yanlızca kategori bilgilerini güncelleyebilirsiniz. </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-6">

                                            <div className="text  mb-2"><b> Ürün Grubu</b></div>
                                            <select disabled className="w-100 form-control mt-0" onChange={(e) => { setProductType(e.target.value); setShowGroup([]); SelectOption(0, -1); SelectOption(1, -1) }} value={productType}>
                                                <option value={-1}>Lütfen bir seçim yapın</option>
                                                <option value={1}>Tek ürün</option>
                                                <option value={2}>Alt ürünler</option>
                                                <option value={3}>Tek alt ürün</option>
                                            </select>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="text  mb-2"><b> Ürün Kategorisi</b></div>
                                            <select className="w-100 form-control mt-0" onChange={(e) => { setProductCategory(e.target.value) }} value={productCategory}>
                                                <option value={-1}>Lütfen bir seçim yapın</option>
                                                {
                                                    categoryList.map(item => {
                                                        return <option value={item.ek_id}>{item.ek_category_name}</option>
                                                    })
                                                }
                                            </select>
                                        </div>
                                    </div>





                                    {
                                        productType == 2 ? <>
                                            <div className="row">

                                                {
                                                    // JSON.stringify(category)
                                                }

                                                <div className="col-md-6 mt-4">
                                                    <div className="text  mb-2"><b>1.Ürün Grubu</b></div>
                                                    <select disabled={true} value={optionGroupOne[0]} className="w-100 form-control mt-0" onChange={(e) => { SelectOption(0, e.target.value); SelectOption(1, -1) }}>
                                                        <option value={-1}>Lütfen bir seçim yapın</option>
                                                        {
                                                            category.map((item2) => {
                                                                return <option value={item2.ek_option_group_id}>{item2.ek_tittle}</option>
                                                            })
                                                        }
                                                    </select>
                                                </div>

                                                <div className="col-md-6 mt-4">
                                                    <div className="text  mb-2"><b>2.Ürün Grubu</b></div>
                                                    <select disabled={true} value={optionGroupOne[1]} className="w-100 form-control mt-0" onChange={(e) => { SelectOption(1, e.target.value); }}>
                                                        <option value={-1}>Lütfen bir seçim yapın</option>
                                                        {
                                                            category.map((item2) => {
                                                                return <option value={item2.ek_option_group_id}>{item2.ek_tittle}</option>
                                                            })
                                                        }
                                                    </select>
                                                </div>
                                            </div>


                                        </> : productType == 3 ? <>
                                            <div className="row">

                                                {
                                                    // JSON.stringify(category)
                                                }

                                                <div className="col-md-12 mt-4">
                                                    <div className="text  mb-2"><b>1.Ürün Grubu</b></div>
                                                    <select disabled value={optionGroupOne[0]} className="w-100 form-control mt-0" onChange={(e) => { SelectOption(0, e.target.value); SelectOption(1, -1) }}>
                                                        <option value={-1}>Lütfen bir seçim yapın</option>
                                                        {
                                                            category.map((item2) => {
                                                                return <option value={item2.ek_option_group_id}>{item2.ek_tittle}</option>
                                                            })
                                                        }
                                                    </select>
                                                </div>

                                                {/* <div className="col-md-6 mt-4">
                    <div className="text  mb-2"><b>2.Ürün Grubu</b></div>
                    <select disabled={1 == 0 ? false : !(1 <= groupList.length)} value={optionGroupOne[1]} className="w-100 form-control mt-0" onChange={(e) => { SelectOption(1, e.target.value); }}>
                        <option value={-1}>Lütfen bir seçim yapın</option>
                        {
                            category.map((item2) => {
                                return <option value={item2.ek_option_group_id}>{item2.ek_tittle}</option>
                            })
                        }
                    </select>
                </div> */}
                                            </div>


                                        </> : ""
                                    }

                                    {
                                        showGroup && showGroup.length > 0 ? <div className="row mt-4">
                                            <>
                                                <div className="col-md-3">
                                                    <div className="text px-2 mb-2"><b>Envanter adı</b></div>
                                                </div>
                                                <div className="col-md-2">
                                                    <div className="text px-2 mb-2"><b>Alış Fiyatı</b></div>
                                                </div>
                                                <div className="col-md-2">
                                                    <div className="text px-2 mb-2"><b>Satış Fiyatı</b></div>
                                                </div>
                                                <div className="col-md-2">
                                                    <div className="text px-2 mb-2"><b>Stok adeti</b></div>
                                                </div>
                                                <div className="col-md-2">
                                                    <div className="text px-2 mb-2"><b>Mal Giriş Tarihi</b></div>
                                                </div>

                                            </>
                                            {
                                                showGroup && showGroup.map((item) => {
                                                    return <StockItem item={item} ></StockItem>
                                                })
                                            }
                                        </div> : ""
                                    }

                                </div>
                            </div>

                        </div>
                        <div className="card-footer">
                            <button onClick={UpdateProduct} type="reset" className="btn btn-primary mr-2">Kategori Güncelle</button>
                        </div>
                    </form>
                    {/*end::Form*/}
                </div>

                <div className="card card-custom gutter-b example example-compact">
                    <div className="card-header">
                        <h3 className="card-title">Stok ve Fiyat Bilgisi</h3>
                        <div className="card-toolbar">
                            <ProductStockInsert productType={productType} category={category} groupList={groupList} optionGroupOne={optionGroupOne} insertClick={InsertStockPool} />
                        </div>
                    </div>
                    {/*begin::Form*/}
                    <form>
                        <div className="card-body">
                            asf
                            {
                                stock == undefined ? "a" : "1"
                            }
                            {
                                stock == undefined ? "" : stock.map((itemm, index) => {

                                    return <div className="row mb-6">


                                        <div className="col-md-12 mb-4">
                                            <b className="text">
                                                {GetGroupName(itemm.ek_first_option_group) + "-" + GetGroupName(itemm.ek_second_option_group)}
                                            </b>
                                        </div>

                                        <div className="col-md-4">
                                            <label>Alış Fiyatı<span className="text-danger">*</span></label>
                                            <input type="email" className="form-control" onChange={(e) => { stock[index].ek_buy_price = e.target.value }} placeholder="Kategori Adı" defaultValue={itemm.ek_buy_price} />
                                        </div>
                                        <div className="col-md-4">
                                            <label>Satış Fiyatı<span className="text-danger">*</span></label>
                                            <input type="email" className="form-control" onChange={(e) => { stock[index].ek_sell_price = e.target.value }} placeholder="Kategori Adı" defaultValue={itemm.ek_sell_price} />
                                        </div>
                                        <div className="col-md-4">
                                            <label>Adet<span className="text-danger">*</span></label>
                                            <input type="email" className="form-control" onChange={(e) => { stock[index].ek_stock = e.target.value }} placeholder="Kategori Adı" defaultValue={itemm.ek_stock} />
                                        </div>
                                        <div className="col-md-4">
                                            <label>Tarih<span className="text-danger">*</span></label>
                                            <input type="email" className="form-control" placeholder="Kategori Adı" defaultValue={itemm.ek_date} />
                                        </div>
                                        <div className="col-md-8">
                                            <label>İşlem<span className="text-danger">*</span></label>
                                            <div className="w-100">
                                                <div className="btn-group w-100">
                                                    <div className="btn btn-block btn-light-primary" onClick={() => {
                                                        DataCenter().Product.Stock.Remove(itemm.ek_stock_id).then(r => {
                                                            DataCenter().Product.Stock.Insert(itemm.ek_first_option_group, itemm.ek_second_option_group, itemm.ek_buy_price, itemm.ek_sell_price, itemm.ek_stock, "", id).then(r => {
                                                                DataCenter().Product.Stock.List(id).then(rr => {

                                                                    setStock(rr);
                                                                    MyHelper().AlertSuccess();
                                                                })
                                                            })

                                                        });
                                                    }}>Güncelle</div>
                                                    <div className="btn btn-block btn-light-danger m-0" onClick={() => {
                                                        DataCenter().Product.Stock.Remove(itemm.ek_stock_id).then(r => {
                                                            DataCenter().Product.Stock.List(id).then(rr => {

                                                                setStock(rr);
                                                            })
                                                        });
                                                    }}>Sil</div>
                                                </div>
                                            </div>


                                        </div>

                                    </div>

                                })
                            }

                        </div>
                    </form>
                    {/*end::Form*/}
                </div>
                <div className="card card-custom gutter-b example example-compact">
                    <div className="card-header">
                        <h3 className="card-title">Ürün Resimleri</h3>
                        <div className="card-toolbar">

                        </div>
                    </div>
                    {/*begin::Form*/}
                    <form>
                        <div className="card-body">

                            <MediaPicker multiple="true" onSelected={SelectedImages} >
                                <div className="imagePickerArea d-flex justify-content-center" style={{ border: "dotted 2px black", borderRadius: "20px" }}>

                                    {
                                        images.length == 0 && (productData == undefined || (productData != undefined && productData.images == "")) ? <div class="align-self-center">
                                            <FontAwesomeIcon icon={faCamera} style={{ fontSize: 30, color: "gray" }} />

                                        </div> : ""
                                    }


                                    {
                                        images.length != 0 || (productData != undefined && productData.images != "") ? <div className="align-self-center w-100 p-4">
                                            <div className="row justify-content-center">
                                                {
                                                    images.map((item) => {
                                                        return <ImagesBox item={item.url} />
                                                    })
                                                }
                                                {
                                                    productData.images.split(",").length == 0 ? "" : productData.images.split(",").map((item) => {
                                                        return <ImagesBoxData item={item.split("$")[0]} imageID={item.split("$")[1]} />
                                                    })
                                                }
                                            </div>

                                        </div> : ""}



                                </div>
                            </MediaPicker>

                        </div>
                        <div className="card-footer">
                            <button disabled={images.length == 0} onClick={UpdateImages} type="reset" className="btn btn-primary mr-2">Fotoğraf Güncelle</button>
                        </div>
                    </form>
                    {/*end::Form*/}
                </div>

            </div>

        </div>
    </>
}