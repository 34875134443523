import axios from "axios";

export default function Trendyol() {
    function List(storeID, username, password) {
        return new Promise((ok, fail) => {
            

            var session_url = 'https://api.trendyol.com/sapigw/suppliers/205584/products?approved=true';
            var username = 'DnOLsIoPO3qbxy10NLtB';
            var password = 'Wm0gRr6eIC0xXuK20N45';
            var basicAuth = 'Basic ' + btoa(username + ':' + password);
           
            
            axios.get(session_url, {
                mode: 'no-cors',
                headers: { 
                    'Authorization':  basicAuth,
                    "Access-Control-Allow-Origin" : "*",
                    'Content-Type': 'application/json',
                 },
                 credentials: 'same-origin',
            }).then(function (response) {
                console.log('Authenticated');
            }).catch(function (error) {
                console.log(error);
                console.log('Error on Authentication');
            });

        });

    }
    return {
        List
    }
}