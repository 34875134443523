import axios from "axios";
import Global from "../../Globa";
import { DMProduct, DMProductGroup, DMRequestResponse } from "../DataModel/DataModel";

export default function DBGroup()
{
    

    function List() : Promise<DMProductGroup[]> {
        
        return new Promise(function (resolve, reject) {
            axios.post(Global().URL, {
                command: 'product_option_group_list',
            })
                .then(function (response) {
                    console.log(response.data);

                    var itemList: DMProductGroup[] = []
                    
                    for(var i = 0 ; i<response.data.length;i++)
                    {
                        if(response.data[i].ek_parent_id == -1)
                        {
                            var item = new DMProductGroup(response.data[i])
                            
                            itemList.push(item);
                        }
                    }
                    for(var i = 0 ; i<response.data.length;i++)
                    {
                        if(response.data[i].ek_parent_id != -1)
                        {
                            itemList.map((item,index)=>{

                                
                                if(item.ek_option_group_id == response.data[i].ek_parent_id)
                                {
                                    itemList[index].ek_children.push( new DMProductGroup(response.data[i]));
                                }
                            })
                        }
                    }
                    

                  
                    resolve(itemList);

                    
                })
                .catch(function (error) {
                    console.log(error);
                });
        });
    
    }

    function Insert(name,subGroup)
    {
        return new Promise((ok, fail) => {
            axios.post(Global().URL, {
                command: 'product_option_group_insert',
                optionGroupName: name,
                subGroupList: subGroup,
            })
                .then(function (response) {
                    ok(response.data);
                })
                .catch(function (error) {
                    console.log(error);
                });
        })
    }
    function Update(id,name)
    {
        return new Promise((ok, fail) => {
            axios.post(Global().URL, {
                command: 'product_option_group_update',
                groupID: id,
                groupName: name,
            })
                .then(function (response) {
                    ok(response.data);
                })
                .catch(function (error) {
                    console.log(error);
                });
        })
    }
    function SubGroupDelete(id)
    {
        return new Promise((ok, fail) => {
            axios.post(Global().URL, {
                command: 'product_option_sub_group_delete',
                parentID: id,
            })
                .then(function (response) {
                    ok(response.data);
                })
                .catch(function (error) {
                    console.log(error);
                });
        })
    }
    function SubGroupUpdate(id,text)
    {
        return new Promise((ok, fail) => {
            axios.post(Global().URL, {
                command: 'product_option_sub_group_update',
                optionSubGroupID: id,
                optionSubGroupName: text,
            })
                .then(function (response) {
                    ok(response.data);
                })
                .catch(function (error) {
                    console.log(error);
                });
        })
    }
    function SubGroupInsert(id,text)
    {
        return new Promise((ok, fail) => {
            axios.post(Global().URL, {
                command: 'product_option_sub_group_insert',
                parentID: id,
                groupName: text,
            })
                .then(function (response) {
                    ok(response.data);
                })
                .catch(function (error) {
                    console.log(error);
                });
        })
    }
    function Detail(id)
    {
        return new Promise((ok, fail) => {
            axios.post(Global().URL, {
                command: 'product_option_group_detail',
                groupID: id,
            })
                .then(function (response) {
                    // console.log(response);

                    ok(response.data);
                })
                .catch(function (error) {
                    console.log(error);
                });
        })
    }

    function Remove(id)
    {
        return new Promise((ok, fail) => {
            axios.post(Global().URL, {
                command: 'product_option_group_delete',
                groupID: id
            })
                .then(function (response) {
                    ok(response.data);
                })
                .catch(function (error) {
                    console.log(error);
                });
        })
    }
    return {
        List,Insert,Update,SubGroupDelete,SubGroupUpdate,SubGroupInsert,Detail,Remove
    }
}