import React, { useState, useEffect, useRef } from 'react'
import { Ckeditor } from '../../component/Ckeditor'

import MediaPicker from "../../../Cactus/component/media_picker";

import { faCamera, faPlusCircle, faRuler, faTimes, faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Validator, { ValidationController } from '../../../Cactus/helper/Validator';
import AlertEkmekk from "../../helper/alert";
import DatePicker from "react-datepicker";

import MyHelper from "../../helper/MyHelper";
import { useHistory } from "react-router-dom";
import DataCenter from '../../Core/Database/DataCenter';
import { DMProduct } from '../../Core/Database/DataModel/DataModel';


export default function ProductInsert() {

       const productModel: DMProduct[] = [];
    const productCategoryModel: DMProductCategory[] = [];
    const [productDescription, setProductDescription] = useState()

    var history = useHistory();

    const [products, setProducts] = useState(productModel);
    const [images, setImages] = useState([]);

    const [category, setCategory] = useState([])

 
  
    const [productList, setProductList] = useState(productModel);
    const [filterProductList, setFilterProductList] = useState(productModel);
    const [categoryList, setCategoryList] = useState(productCategoryModel);

    useEffect(() => {

        DataCenter().Product.Category.List().then(r => {
            console.log(r)
            setCategoryList(r);
        })

        DataCenter().Product.Group.List().then((r)=>{
            setCategory(r);
        });
        
    }, [])



    const [groupList, setGroupList] = useState([]);
    const [showGroup, setShowGroup] = useState([]);
    const [productType, setProductType] = useState(-1);
    const [productCategory, setProductCategory] = useState(-1);

    const inputTittle = useRef("");
    const [titleProd, setTitleProd] = useState("")

    const [inputSingleBuyPrice, setInputSingleBuyPrice] = useState(0);
    const [inputSingleSellPrice, setInputSingleSellPrice] = useState(0);
    const [inputSingleStockPrice, setInputSingleStockPrice] = useState(0);
    const [inputSingleStockDate, setInputSingleStockDate] = useState(Date);

    const [optionGroupOne, setOptionGroupOne] = useState([]);

    const [showGroupIndex, setShowGroupIndex] = useState(0);


    function SelectedImages(images2) {

        setImages(images2)
    }

    function InsertProduct() {

        var str = productDescription != undefined ? productDescription : ""

        var edittedDescription = str.replace(/'/g, "\\'");

        var validator = new ValidationController();

        if (validator.Control() == 0) {

            if (images.length == 0) {

                MyHelper().AlertFillAll("Lütfen Bir Fotoğraf Yükleyiniz")
            }
            else {

                MyHelper().AlertSure((e) => {

                    if (e) {

                        var bash = [];

                        for (let i = 0; i < images.length; i++) {

                            // bash.push
                        }

                        var data = {
                            tittle: titleProd,
                            description: productDescription,
                            images: images,
                            //stock: []
                        }

                        // if (productType == 2) {
                        //     showGroup.map(item => {
                        //         alert(item.buyPrice);
                        //         if (item.buyPrice != "0" && item.buyPrice != 0) {
                        //             var datas = {
                        //                 item1: item.item1.ek_option_group_id,
                        //                 item2: item.item2.ek_option_group_id,
                        //                 buyPrice: item.buyPrice.toString().replace(",", "."),
                        //                 sellPrice: item.sellPrice.toString().replace(",", "."),
                        //                 stock: item.stock,
                        //                 date: item.date,
                        //             }
                        //             data.stock.push(datas);
                        //         }

                        //     })
                        // }
                        // else {

                        //     showGroup.map(item => {
                        //         // alert(item.buyPrice);
                        //         if (item.buyPrice != "0" && item.buyPrice != 0) {
                        //             var datas = {
                        //                 item1: item.item1.ek_option_group_id,
                        //                 item2: item.item2.ek_option_group_id,
                        //                 buyPrice: item.buyPrice.toString().replace(",", "."),
                        //                 sellPrice: item.sellPrice.toString().replace(",", "."),
                        //                 stock: item.stock,
                        //                 date: item.date,
                        //             }
                        //             data.stock.push(datas);
                        //         }

                        //     })


                        //     data.stock.push({
                        //         item1: -1,
                        //         item2: -1,
                        //         buyPrice: inputSingleBuyPrice.replace(",", "."),
                        //         sellPrice: inputSingleSellPrice.replace(",", "."),
                        //         stock: inputSingleStockPrice,
                        //         date: inputSingleStockDate,
                        //     })

                        // }
                        
                      
                        

                        var jsonData = {
                           "categoryID" : productCategory,
                           "ek_product_description" : edittedDescription,
                           "ek_short_description" : edittedDescription,
                           "optionGroupOne" : optionGroupOne[0] == undefined ? -1 : optionGroupOne[0],
                           "optionGroupTwo" : optionGroupOne[1] == undefined ? -1 : optionGroupOne[1],
                           "ek_product_name" : data.tittle,
                           "images" :  data.images,
                           
                        }
                        alert(JSON.stringify(jsonData))

                        DataCenter().Product.Insert(new DMProduct(jsonData)).then(()=>{

                        });

                        

                    }


                }, "ürün eklenecek")



            }


        }
        else {
            AlertEkmekk().FillAll();

            // scroll.scrollToTop();
        }

    }


    function SelectOption(index, value) {
        optionGroupOne[index] = value;
        setOptionGroupOne([...optionGroupOne]);
        if (value == -1) {

            groupList.splice(index, groupList.length);
            setGroupList([...groupList])
        }
        else {

            console.log()

            category.map((item) => {

                if (item.ek_option_group_id == value) {
                    groupList[index] = item;
                }
            })


            setGroupList([...groupList])


        }

        // var bashGroup = [];


        // groupList.map((item1) => {
        //     item1.items.map((subItem1) => {
        //         groupList.map((item2) => {
        //             item2.items.map((subItem2) => {

        //                 if (item1 != item2 && subItem2 != subItem1) {
        //                     var deneme = false;
        //                     bashGroup.map((oItem) => {
        //                         if (oItem.item1 == subItem2 || oItem.item2 == subItem1) {
        //                             deneme = true;
        //                         }

        //                     })
        //                     if (!deneme) {

        //                         bashGroup.push({ item1: subItem1, item2: subItem2, buyPrice: 0, sellPrice: 0, stock: 0 });
        //                     }

        //                 }

        //             })
        //         })
        //     })
        // })

        // setShowGroup(bashGroup)




    }

    function InsertStockPool(item) {

        console.log(item);
        item[0].index = showGroupIndex;

        showGroup.push(item[0]);

        setShowGroup([...showGroup]);
        console.log(showGroup);

        setShowGroupIndex(item[0].index + 1);
    }

    function ImagesBox({ item }) {
        return <div className="image-input image-input-outline mr-6 mt-6" id="kt_profile_avatar" style={{ backgroundImage: 'url(/metronic/theme/html/demo7/dist/assets/media/users/blank.png)' }}>
            <div className="image-input-wrapper" style={{ backgroundImage: 'url(' + item + ')' }} />
            <label className="btn btn-xs btn-icon btn-circle btn-white btn-hover-text-primary btn-shadow" data-action="change" data-toggle="tooltip" title data-original-title="Change avatar">
                <FontAwesomeIcon icon={faTimes} />
            </label>
        </div>
    }

    

    function ChangeValue(type, item, data) {

        // console.log(data);
        if (type == "buy") {
            showGroup.map((i, index) => {
                if (i == item) {
                    showGroup[index].buyPrice = data.replace(",", ".");
                }
            })
        }
        else if (type == "sell") {
            showGroup.map((i, index) => {
                if (i == item) {
                    showGroup[index].sellPrice = data.replace(",", ".");
                }
            })
        }
        else if (type == "stock") {
            showGroup.map((i, index) => {
                if (i == item) {
                    showGroup[index].stock = data.replace(",", ".");
                }
            })
        }
        else if (type == "date") {
            showGroup.map((i, index) => {
                if (i == item) {
                    showGroup[index].date = data;
                    setShowGroup([...showGroup]);
                }
            })
        }

    }


    function StockItem({ item }) {
        // console.log(item)
        return <div className="row mt-4">

            {/* <div className="col-md-3">
                <div className="text px-2 mb-2"><b>{item.item1.ek_tittle + "-" + item.item2.ek_tittle}</b></div>

            </div> */}
            {
                item.item1.length == 0 && item.item2.length == 0 ? <div className="col-md-3">
                    <div className="text px-2 mb-2"><b>Ürün bilgileri</b></div>
                </div> : <div className="col-md-3">
                    <div className="text px-2 mb-2"><b>{item.item1.length != 0 ? item.item1.ek_tittle : ""}{item.item2.length != 0 ? " - " + item.item2.ek_tittle : ""}</b></div>

                </div>
            }

            <div className="col-md-2">
                <input onChange={(e) => { ChangeValue("buy", item, e.target.value) }} data-validation-rule="notNull|onlyNumber" type="text" name="bash" className="form-control mt-0" defaultValue={item.buyPrice} />
            </div>
            <div className="col-md-2">
                <input onChange={(e) => { ChangeValue("sell", item, e.target.value) }} data-validation-rule="notNull|onlyNumber" type="text" name="bash" className="form-control mt-0" defaultValue={item.sellPrice} />
            </div>
            <div className="col-md-2">
                <input onChange={(e) => { ChangeValue("stock", item, e.target.value) }} data-validation-rule="notNull|onlyNumber" type="text" name="bash" className="form-control mt-0" defaultValue={item.stock} />
            </div>
            <div className="col-md-2">
                <DatePicker dateFormat="dd/MM/yyyy" className="form-control mt-0" selected={item.date} onChange={(date) => ChangeValue("date", item, date)} />
            </div>
            <div className="col-md-1" onClick={() => {
                for (let i = 0; i < showGroup.length; i++) {

                    if (showGroup[i].index == item.index) {
                        showGroup.splice(i, 1);

                        setShowGroup([...showGroup]);
                    }

                }
            }}>
                <FontAwesomeIcon icon={faTrash} />
            </div>
            <hr />


        </div>
    }

    return <>

        <Validator>
            <div className="row">

                <div className="col-md-7">
                    <div className="card card-custom gutter-b example example-compact">
                        <div className="card-header">
                            <h3 className="card-title">Ürün Bilgileri</h3>
                            <div className="card-toolbar">

                            </div>
                        </div>
                        {/*begin::Form*/}
                        <form>
                            <div className="card-body">
                                <div className="form-group mb-8">
                                    <div className="alert alert-custom alert-default" role="alert">
                                        <div className="alert-icon">
                                            <span className="svg-icon svg-icon-primary svg-icon-xl">
                                                {/*begin::Svg Icon | path:/metronic/theme/html/demo7/dist/assets/media/svg/icons/Tools/Compass.svg*/}
                                                <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                                    <g stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
                                                        <rect x={0} y={0} width={24} height={24} />
                                                        <path d="M7.07744993,12.3040451 C7.72444571,13.0716094 8.54044565,13.6920474 9.46808594,14.1079953 L5,23 L4.5,18 L7.07744993,12.3040451 Z M14.5865511,14.2597864 C15.5319561,13.9019016 16.375416,13.3366121 17.0614026,12.6194459 L19.5,18 L19,23 L14.5865511,14.2597864 Z M12,3.55271368e-14 C12.8284271,3.53749572e-14 13.5,0.671572875 13.5,1.5 L13.5,4 L10.5,4 L10.5,1.5 C10.5,0.671572875 11.1715729,3.56793164e-14 12,3.55271368e-14 Z" fill="#000000" opacity="0.3" />
                                                        <path d="M12,10 C13.1045695,10 14,9.1045695 14,8 C14,6.8954305 13.1045695,6 12,6 C10.8954305,6 10,6.8954305 10,8 C10,9.1045695 10.8954305,10 12,10 Z M12,13 C9.23857625,13 7,10.7614237 7,8 C7,5.23857625 9.23857625,3 12,3 C14.7614237,3 17,5.23857625 17,8 C17,10.7614237 14.7614237,13 12,13 Z" fill="#000000" fillRule="nonzero" />
                                                    </g>
                                                </svg>
                                                {/*end::Svg Icon*/}
                                            </span>
                                        </div>
                                        <div className="alert-text">Ürüne ait fiyat bilgileri ürünü ekledikten sonra eklenmektedir.</div>
                                    </div>
                                </div>
                                <div className="form-group">
                                    <label>Ürün Adı
                                        <span className="text-danger">*</span></label>
                                    <input data-validation-rule="notNull" onChange={(e) => setTitleProd(e.target.value)} value={titleProd} type="text" className="form-control" placeholder="Ürün Adı" />
                                    <span className="form-text text-muted">Bu alanı boş bırakamazsınız</span>
                                </div>
                                <div className="form-group">
                                    <label htmlFor="exampleInputPassword1">Ürün Açıklaması
                                        <span className="text-danger">*</span></label>
                                    <Ckeditor onChange={(e) => setProductDescription(e)} />


                                </div>




                            </div>
                            <div className="card-footer">
                                <button onClick={InsertProduct} type="reset" className="btn btn-primary mr-2">Ürünü Ekle</button>
                            </div>
                        </form>
                        {/*end::Form*/}
                    </div>

                </div>
                <div className="col-md-5">
                    <div className="card card-custom gutter-b example example-compact">
                        <div className="card-header">
                            <h3 className="card-title">Ürün Bilgileri</h3>
                            <div className="card-toolbar">

                            </div>
                        </div>
                        {/*begin::Form*/}
                        <form>
                            <div className="card-body">

                                <div className="form-group">
                                    <label htmlFor="exampleInputPassword1">Ürün Fotoğrafları
                                        <span className="text-danger">*</span></label>




                                </div>

                                <div className="row">

                                    <div className="col-md-6">
                                        <div className="text  mb-2"><b> Ürün Grubu</b></div>
                                        <select className="w-100 form-control mt-0" onChange={(e) => { setProductType(e.target.value); setShowGroup([]); SelectOption(0, -1); SelectOption(1, -1) }} value={productType}>
                                            <option value={-1}>Lütfen bir seçim yapın</option>
                                            <option value={1}>Tek ürün</option>
                                            <option value={2}>Alt ürünler</option>
                                            <option value={3}>Tek alt ürün</option>
                                        </select>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="text  mb-2"><b> Ürün Kategorisi</b></div>
                                        <select className="w-100 form-control mt-0" onChange={(e) => { setProductCategory(e.target.value) }} value={productCategory}>
                                            <option value={-1}>Lütfen bir seçim yapın</option>
                                            {
                                                categoryList.map(item => {
                                                    return <option value={item.ek_id}>{item.ek_category_name}</option>
                                                })
                                            }
                                        </select>
                                    </div>


                                    {
                                        productType == 2 ? <>
                                     
                                     
                                           <div className="col-md-6 mt-4">
                                                    <div className="text  mb-2"><b>1.Ürün Grubu</b></div>
                                                    <select disabled={0 == 0 ? false : !(0 <= groupList.length)} value={optionGroupOne[0]} className="w-100 form-control mt-0" onChange={(e) => { SelectOption(0, e.target.value); SelectOption(1, -1) }}>
                                                        <option value={-1}>Lütfen bir seçim yapın</option>
                                                        {
                                                            category.map((item2) => {
                                                                return <option value={item2.ek_option_group_id}>{item2.ek_tittle}</option>
                                                            })
                                                        }
                                                    </select>
                                                </div>

                                                <div className="col-md-6 mt-4">
                                                    <div className="text  mb-2"><b>2.Ürün Grubu</b></div>
                                                    <select disabled={1 == 0 ? false : !(1 <= groupList.length)} value={optionGroupOne[1]} className="w-100 form-control mt-0" onChange={(e) => { SelectOption(1, e.target.value); }}>
                                                        <option value={-1}>Lütfen bir seçim yapın</option>
                                                        {
                                                            category.map((item2) => {
                                                                return <option value={item2.ek_option_group_id}>{item2.ek_tittle}</option>
                                                            })
                                                        }
                                                    </select>
                                                </div>
                                            


                                        </> : productType == 3 ? <>
                                           
                                        <div className="col-md-12 mt-4">
                                                    <div className="text  mb-2"><b>1.Ürün Grubu</b></div>
                                                    <select disabled={0 == 0 ? false : !(0 <= groupList.length)} value={optionGroupOne[0]} className="w-100 form-control mt-0" onChange={(e) => { SelectOption(0, e.target.value); SelectOption(1, -1) }}>
                                                        <option value={-1}>Lütfen bir seçim yapın</option>
                                                        {
                                                            category.map((item2) => {
                                                                return <option value={item2.ek_option_group_id}>{item2.ek_tittle}</option>
                                                            })
                                                        }
                                                    </select>
                                                </div>

                                        </> : ""
                                    }

                                    {
                                        showGroup && showGroup.length > 0 ? <div className="row mt-4">
                                            <>
                                                <div className="col-md-3">
                                                    <div className="text px-2 mb-2"><b>Envanter adı</b></div>
                                                </div>
                                                <div className="col-md-2">
                                                    <div className="text px-2 mb-2"><b>Alış Fiyatı</b></div>
                                                </div>
                                                <div className="col-md-2">
                                                    <div className="text px-2 mb-2"><b>Satış Fiyatı</b></div>
                                                </div>
                                                <div className="col-md-2">
                                                    <div className="text px-2 mb-2"><b>Stok adeti</b></div>
                                                </div>
                                                <div className="col-md-2">
                                                    <div className="text px-2 mb-2"><b>Mal Giriş Tarihi</b></div>
                                                </div>

                                            </>
                                            {
                                                showGroup && showGroup.map((item) => {
                                                    return <StockItem item={item} ></StockItem>
                                                })
                                            }
                                        </div> : ""
                                    }



                                </div>




                            </div>
                        </form>
                        {/*end::Form*/}
                    </div>
                    <div className="card card-custom gutter-b example example-compact">
                        <div className="card-header">
                            <h3 className="card-title">Ürün Bilgileri</h3>
                            <div className="card-toolbar">

                            </div>
                        </div>
                        {/*begin::Form*/}
                        <form>
                            <div className="card-body">

                                <div className="form-group">
                                    <label htmlFor="exampleInputPassword1">Ürün Fotoğrafları
                                        <span className="text-danger">*</span></label>

                                    <MediaPicker multiple="true" onSelected={SelectedImages} >
                                        <div className="imagePickerArea d-flex justify-content-center" style={{ border: "dotted 2px black", borderRadius: "20px" }}>

                                            {
                                                images.length == 0 ? <div class="align-self-center p-10">
                                                    <FontAwesomeIcon icon={faCamera} style={{ fontSize: 30, color: "gray" }} />


                                                </div> : ""
                                            }


                                            {
                                                images.length != 0 ? <div className="align-self-center w-100 p-4">
                                                    <div className="row justify-content-center">
                                                        {
                                                            images.map((item) => {
                                                                return <ImagesBox item={item.url} />
                                                            })
                                                        }
                                                        {
                                                            // productData.images.split(",").length == 0 ? "" : productData.images.split(",").map((item) => {
                                                            //     return <ImagesBoxData item={item} />
                                                            // })
                                                        }
                                                        {
                                                            // images.map((item) => {
                                                            //     return <div className="col-md-2 my-4"><div className="box" onClick={() => { }} style={{ backgroundImage: 'url(' + item.url.replace(" ", "%20") + ')', backgroundSize: "contain", backgroundPosition: "center", backgroundRepeat: "no-repeat", border: (item.selected ? "solid 1px red" : "none") }}>

                                                            //     </div>
                                                            //     </div>
                                                            // })
                                                        }
                                                    </div>

                                                </div> : ""}

                                        </div>
                                    </MediaPicker>




                                </div>



                            </div>
                        </form>
                        {/*end::Form*/}
                    </div>

                </div>


            </div>
        </Validator>
    </>
}



