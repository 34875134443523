import { faPlusCircle, faRuler, faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState } from "react";
import { useEffect } from "react";
import { useHistory } from "react-router-dom";
import MyHelper from "../../../Cactus/helper/MyHelper";
import DataManager from "../../../Cactus/database/DataManager";
import { Button } from "react-bootstrap";
import { GroupInsertModal } from "./GroupInsertModal";
import { GroupUpdateModal } from "./GroupUpdateModal";
import DataCenter from "../../Core/Database/DataCenter";


export const GroupsList = () => {
  const [groups, setGroups] = useState();

  var history = useHistory();
  useEffect(() => {
    DataCenter().Product.Group.List().then(r => {
      console.log(r);
      setGroups(r);

    })

  }, [])


  function RemoveProduct(item) {

    MyHelper().AlertSure((e) => {
      if (e) {
        DataCenter().Product.Group.Remove().then(r => {
          groups.map((subItem, index) => {
            if (item.ek_option_group_id == subItem.ek_option_group_id) {

              groups.splice(index, 1);
            }
          })
          setGroups([...groups]);
        })
        
      }
    }, "grup silinecek")
  }

  function OnDone(response)
  {
    DataCenter().Product.Group.List().then(r => {
      console.log(r);
      setGroups(r);

    })
  }

  return (
    <>
      <div className="alert alert-custom alert-white alert-shadow fade show gutter-b" role="alert">
        <div className="alert-icon">
          <span className="svg-icon svg-icon-primary svg-icon-xl">
            {/*begin::Svg Icon | path:/metronic/theme/html/demo7/dist/assets/media/svg/icons/Tools/Compass.svg*/}
            <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
              <g stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
                <rect x={0} y={0} width={24} height={24} />
                <path d="M7.07744993,12.3040451 C7.72444571,13.0716094 8.54044565,13.6920474 9.46808594,14.1079953 L5,23 L4.5,18 L7.07744993,12.3040451 Z M14.5865511,14.2597864 C15.5319561,13.9019016 16.375416,13.3366121 17.0614026,12.6194459 L19.5,18 L19,23 L14.5865511,14.2597864 Z M12,3.55271368e-14 C12.8284271,3.53749572e-14 13.5,0.671572875 13.5,1.5 L13.5,4 L10.5,4 L10.5,1.5 C10.5,0.671572875 11.1715729,3.56793164e-14 12,3.55271368e-14 Z" fill="#000000" opacity="0.3" />
                <path d="M12,10 C13.1045695,10 14,9.1045695 14,8 C14,6.8954305 13.1045695,6 12,6 C10.8954305,6 10,6.8954305 10,8 C10,9.1045695 10.8954305,10 12,10 Z M12,13 C9.23857625,13 7,10.7614237 7,8 C7,5.23857625 9.23857625,3 12,3 C14.7614237,3 17,5.23857625 17,8 C17,10.7614237 14.7614237,13 12,13 Z" fill="#000000" fillRule="nonzero" />
              </g>
            </svg>
            {/*end::Svg Icon*/}
          </span>
        </div>
        <div className="alert-text">Bu ekranı kullanarak ürünlerinizi gruplandırabilirsiniz. Aynı ürünün birden fazla çeşidi varsa <b>Alt Ürün Grubu</b> ekranıyla tek bir ürün içerisinde birden fazla fiyat ve stok girişi yapmanızı sağlar.(Tshirt bedenleri ve renkleri)</div>
      </div>

      <div className="card card-custom gutter-b">
        {/*begin::Header*/}
        <div className="card-header border-0 py-5">
          <h3 className="card-title align-items-start flex-column">
            <span className="card-label font-weight-bolder text-dark">Alt Grup Listesi</span>
            <span className="text-muted mt-3 font-weight-bold font-size-sm">Toplam {groups && groups.length} tane alt grup bulundu.</span>
          </h3>
          <div className="card-toolbar">
            <GroupInsertModal onDone={OnDone} />
          </div>
        </div>
        {/*end::Header*/}
        {/*begin::Body*/}
        <div className="card-body pt-0 pb-3">
          {/*begin::Table*/}
          <div className="table-responsive">
            <table className="table table-head-custom table-head-bg table-borderless table-vertical-center">
              <thead>
                <tr className="text-uppercase">
                  <th style={{ minWidth: '250px' }} className="pl-7">
                    <span className="text-dark-75">Grup Adı</span>
                  </th>
                  <th style={{ minWidth: '100px' }}>Alt Grup Sayısı</th>
                  <th style={{ minWidth: '100px' }}></th>
                  <th style={{ minWidth: '150px' }}>Ürün Sayısı</th>
                  <th style={{ minWidth: '130px' }}></th>
                  <th style={{ minWidth: '120px' }}>İşlemler</th>
                </tr>
              </thead>
              <tbody>
                {
                  groups && groups.map(group => {
                    return (
                      <tr>
                        <td className="pl-0 py-0">
                          <div className="d-flex align-items-center">
                            <div>
                              <a className="text-dark font-weight-bolder text-hover-primary mb-1 font-size-lg">{group.ek_tittle}</a>
                            </div>
                          </div>
                        </td>
                        <td>
                          <span className="text-dark-75 font-weight-bolder d-block font-size-lg">{group.subGroupCount}</span>
                        </td>
                        <td>
                        </td>
                        <td>
                          <span className="text-dark-75 font-weight-bolder d-block font-size-lg">{group.productCount}</span>
                        </td>
                        <td>
                        </td>
                        <td className="text-right pr-0">
                          <GroupUpdateModal id={group.ek_option_group_id} />
                          <a onClick={() => {
                            MyHelper().AlertSure((e) => {

                              if (e) {
                                DataCenter().Product.Group.Remove(group.ek_option_group_id).then(r => {
                                  groups.map((subItem, index) => {
                                    if (group.ek_option_group_id == subItem.ek_option_group_id) {

                                      groups.splice(index, 1);
                                    }
                                  })
                                  setGroups([...groups]);
                                })
                                

                              }
                            }, "ürün silinecek")
                          }} className="btn btn-light-danger btn-sm ml-4 " data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">Sil</a>


                        </td>
                      </tr>
                    )
                  })
                }
              </tbody>
            </table>
          </div>
          {/*end::Table*/}
        </div>
        {/*end::Body*/}
      </div>

    </>
  )
}
