import React, { useEffect, useState } from "react"
import DataManager from "../../database/DataManager"

import { NavLink } from "react-router-dom"
import CategoryModal from "./cateogry_modal";
import DataCenter from "../../Core/Database/DataCenter";

export default function CategoryList() {


    const [categoryList, setCategoryList] = useState([]);
    useEffect(() => {

        DataCenter().Product.Category.List().then(r=>{
            setCategoryList(r);
        })
        
    }, [])

    
    function insertOnDone(tittle)
    {
        categoryList.push({
            "ek_category_name" : tittle,
            "ek_id":"-1",
            "ek_remove":"0",
            "productCount":"0",

        })
        setCategoryList([...categoryList])

    }

    function OnRemove(id)
    {
        categoryList.map((item,index) =>{
            if(item.ek_id == id)
            {
                categoryList.splice(index,1);
            }
        })
        setCategoryList([...categoryList])
    }
    function OnUpdate(newTittle,id)
    {
        categoryList.map((item,index) =>{
            if(item.ek_id == id)
            {
                categoryList[index].ek_category_name =newTittle;
            }
        })
        setCategoryList([...categoryList])
    }
   return <>
    
        <div className="row">
            <div className="col-xl-12">
                {/*begin::List Widget 20*/}
                <div className="card card-custom card-stretch gutter-b">
                    {/*begin::Header*/}
                    <div className="card-header border-0 pt-6 mb-2">
                        <h3 className="card-title align-items-start flex-column">
                            <span className="card-label font-weight-bold font-size-h4 text-dark-75 mb-3">KAtegori Listesi</span>
                            <span className="text-muted font-weight-bold font-size-sm"> Sistemde Kayıtlı  {categoryList.length} Kategori Bulunuyor </span>
                        </h3>
                        <div className="card-toolbar">
                            <CategoryModal type={"insert"} onDone={insertOnDone} />
                        </div>
                    </div>
                    {/*end::Header*/}
                    {/*begin::Body*/}
                    <div className="card-body pt-2">
                        {/*begin::Table*/}
                        <div className="table-responsive">
                            <table className="table table-borderless mb-0">
                                <tbody>
                                    
                                    {
                                        categoryList.map((item) => {
                                            return <tr>
                                                
                                                
                                                <td className="align-middle pb-6">
                                                    <div className="font-size-lg font-weight-bolder text-dark-75 mb-1">{item.ek_category_name}</div>
                                                    <div className="font-weight-bold text-muted">Kategori Adı</div>
                                                </td>
                                                <td className="text-right align-middle pb-6">
                                                    <div className="font-weight-bold text-muted mb-1">Ürün Sayısı</div>
                                                    <div className="font-size-lg font-weight-bolder text-dark-75">{item.productCount} ₺</div>
                                                </td>
                                                <td className="text-right align-middle pb-6">
                                                    <CategoryModal item={item} onUpdate={OnUpdate} onRemove={OnRemove} type={"update"} />
                                                </td>
                                                {/*end::Content*/}
                                            </tr>

                                        })
                                    }


                                </tbody>
                            </table>
                        </div>
                        {/*end::Table*/}
                    </div>
                    {/*end::Body*/}
                </div>
                {/*end::List Widget 20*/}
            </div>

        </div>
    </>
}
