import React, { useEffect, useState } from "react"
import { useHistory } from "react-router";
import { NavLink } from "react-router-dom"
import DataCenter from "../../Core/Database/DataCenter"
import { DMOrders } from "../../Core/Database/DataModel/DataModel";

export default function OrderList() {
    let history = useHistory()

    const orderModel: DMOrders[] = [];
    const [orderList, setOrderList] = useState(orderModel);
    useEffect(() => {
        
        DataCenter().Orders.List().then((r) => {
            setOrderList(r);

            console.log(r);
        })
    }, [])
    return <>
        <div className="row">
            <div className="col-xl-12">
                {/*begin::List Widget 20*/}
                <div className="card card-custom card-stretch gutter-b">
                    {/*begin::Header*/}
                    <div className="card-header border-0 pt-6 mb-2">
                        <h3 className="card-title align-items-start flex-column">
                            <span className="card-label font-weight-bold font-size-h4 text-dark-75 mb-3">Sipariş Listesi</span>
                            <span className="text-muted font-weight-bold font-size-sm"> Sistemde Kayıtlı  {orderList.length} Sipariş Bulunuyor </span>
                        </h3>

                    </div>
                    {/*end::Header*/}
                    {/*begin::Body*/}
                    <div className="card-body pt-2">
                        {/*begin::Table*/}
                        <div className="table-responsive">
                            <table className="table table-borderless mb-0">
                                <tbody>

                                    {
                                        orderList.map((item) => {
                                            return <tr>

                                                <td className="align-middle pb-6">
                                                    <div className="font-size-lg font-weight-bolder text-dark-75 mb-1">{item.ek_user_name + " " + item.ek_user_surname}</div>
                                                    <div className="font-weight-bold text-muted">{item.ek_phone}</div>
                                                </td>


                                                <td className="text-right align-middle pb-6">
                                                    <div className="font-weight-bold text-muted mb-1">Ödeme Yöntemi</div>
                                                    <div className="font-size-lg font-weight-bolder text-dark-75">{item.ek_payment_type} </div>
                                                </td>
                                                <td className="text-right align-middle pb-6">
                                                    <div className="font-weight-bold text-muted mb-1">Oluşturma Tarihi</div>
                                                    <div className="font-size-lg font-weight-bolder text-dark-75">{item.ek_create_date} ₺</div>
                                                </td>
                                                <td className="text-right align-middle pb-6">
                                                    <a onClick={() => history.push('/orders/detail/'+item.ek_order_id)} className="btn btn-light-primary btn-sm " data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">Görüntüle</a>

                                                </td>
                                                {/*end::Content*/}
                                            </tr>

                                        })
                                    }


                                </tbody>
                            </table>
                        </div>
                        {/*end::Table*/}
                    </div>
                    {/*end::Body*/}
                </div>
                {/*end::List Widget 20*/}
            </div>

        </div>
    </>
}