import React from "react";
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link
} from "react-router-dom";
import CategoryList from "../Pages/Category/category_list";
import OrderDetail from "../Pages/Order/order_detail";
import OrderList from "../Pages/Order/order_list";
import ProductInsert from "../Pages/Product/product_insert";
import ProductList from "../Pages/Product/product_list";
import Blog from '../Pages/Blog/Blog'
import ProductUpdate from "../Pages/Product/product_update";
import { GroupsList } from "../Pages/Groups/GroupsList";

import SiteSettings from "../Pages/Settings/settings_home";
import Home from "../Pages/Home/Hone";
import SettingsNew from "../Pages/Settings/settings_new";
import Trendyol from "../Pages/Partners/trendyol";

export default function Body() {
    return <>
        <Route path="/about">
            sfdxgsdfgsd
        </Route>
        <Route exact path="/product">
            <ProductList />
        </Route>
        <Route exact path="/product/insert">
            <ProductInsert />
        </Route>
        <Route exact path="/product/detail/:id">
            <ProductUpdate />
        </Route>
        <Route exact path="/blog">
            <Blog />
        </Route>
        <Route exact path="/orders">
            <OrderList />
        </Route>
        <Route exact path="/orders/detail/:id">
            <OrderDetail />
        </Route>
        <Route exact path="/product/category">
            <CategoryList />
        </Route>
        <Route exact path="/product/sub-group">
            <GroupsList />
        </Route>
        <Route exact path="/settings">
            <SettingsNew/>
        </Route>
        <Route exact path="/settings/partners">
            <Trendyol/>
        </Route>
        <Route exact path="/settings/blog">
            <Blog/>
        </Route>
        
        
        <Route exact path="/settings/a/:id">
            <SiteSettings />
        </Route>
        <Route exact path="/">
            <Home/>
        </Route>
      
    </>
}
