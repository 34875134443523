import React, { useEffect, useState } from "react"
import DataManager from "../../database/DataManager"
import Button from 'react-bootstrap/Button';

import BlogModal from "./BlogModal";
import MyHelper from "../../helper/MyHelper";

export default function CategoryList() {


    const [blogList, setBlogList] = useState([]);
    useEffect(() => {
        DataManager().Blog.List().then((r) => {
            setBlogList(r);

            console.log(r);
        })
    }, [])

    
   return <>
    
        <div className="row">
            <div className="col-xl-12">
                {/*begin::List Widget 20*/}
                <div className="card card-custom card-stretch gutter-b">
                    {/*begin::Header*/}
                    <div className="card-header border-0 pt-6 mb-2">
                        <h3 className="card-title align-items-start flex-column">
                            <span className="card-label font-weight-bold font-size-h4 text-dark-75 mb-3">Blog Listesi</span>
                            <span className="text-muted font-weight-bold font-size-sm"> Sistemde Kayıtlı  {blogList.length} Blog Bulunuyor </span>
                        </h3>
                        <div className="card-toolbar">
                            <BlogModal type={"insert"} setBlogList={(e) => setBlogList(e)} />
                        </div>
                    </div>
                    {/*end::Header*/}
                    {/*begin::Body*/}
                    <div className="card-body pt-2">
                        {/*begin::Table*/}
                        <div className="table-responsive">
                            <table className="table table-borderless mb-0">
                                <tbody>
                                    
                                    {
                                        blogList && blogList.map((item,index) => {
                                            return <tr>
                                                
                                                
                                                <td className="align-middle pb-6">
                                                <div className="font-weight-bold text-muted">Blog Adı</div>
                                                    <div className="font-size-lg font-weight-bolder text-dark-75 mb-1">{item.ek_tittle}</div>
                                                </td>
                                                <td className="text-right align-middle pb-6">
                                                    <div className="font-weight-bold text-muted mb-1">Etiket</div>
                                                    <div className="font-size-lg font-weight-bolder text-dark-75">{item.ek_tag} </div>
                                                </td>
                                                <td className="text-right align-middle pb-6">
                                                    <div className="font-weight-bold text-muted mb-1">Oluşrulma Tarihi</div>
                                                    <div className="font-size-lg font-weight-bolder text-dark-75">{item.ek_create_date} </div>
                                                </td>
                                                <td className="text-right align-middle pb-6 ml-2">
                                                    <BlogModal type={"update"} data={item} />
                                                    <Button variant={"light-danger"} onClick={() => {
                                                                MyHelper().AlertSure((e) => {

                                                                    if (e) {
                                                                       var dummuy = [...blogList]
    
                                                        
                                                                        DataManager().Blog.Remove(item.ek_blog_id).then((r) => {
                                                                            console.log(r);
                                                                        dummuy.splice(index,1)

                                                                        setBlogList(dummuy)

                                                                        })
                                                        
                                                        
                                                                    }
                                                        
                                                        
                                                                }, "blog silinecek")
                                                        
                                                    }} >
                                                      Sil
                                                    </Button>
                                                </td>
                                                {/*end::Content*/}
                                            </tr>

                                        })
                                    }


                                </tbody>
                            </table>
                        </div>
                        {/*end::Table*/}
                    </div>
                    {/*end::Body*/}
                </div>
                {/*end::List Widget 20*/}
            </div>

        </div>
    </>
}
