import React, { useEffect, useState } from 'react'
import Editor from 'ckeditor5-custom-build/build/ckeditor';
import { CKEditor } from '@ckeditor/ckeditor5-react'





export const Ckeditor = ({ onChange, editorText }) => {
    var htmlArea = "";
    const [editorG, setEditorG] = useState();
    const [show, setShow] = useState(false);
    const [editorConfiguration, setEditorConfiguration] = useState();


    useEffect(() => {
        setEditorConfiguration({
            toolbar: ['heading', '|',
                'fontfamily', 'fontsize', '|',
                'alignment', '|',
                'fontColor', 'fontBackgroundColor', '|',
                'bold', 'italic', 'strikethrough', 'underline', 'subscript', 'superscript', '|',
                'link', '|',
                'outdent', 'indent', '|',
                'bulletedList', 'numberedList', 'todoList', '|',
                'insertTable', '|',
                'image', 'blockQuote', '|',
                'undo', 'redo']




        });

        
    },[])

    
    return (
        <>
        {editorConfiguration == undefined ? ""  : <CKEditor
                editor={Editor}
                data={htmlArea}
                config={editorConfiguration}
                onReady={editor => {
                    setEditorG(editor)
                    console.log('Editor is ready to use!', editor);
                    if (editorText)
                        editor.setData(editorText)
                }}
                onChange={(event, editor) => {
                    const data = editor.getData();
                    htmlArea = data;
                    onChange(data)
                }}
                onBlur={(event, editor) => {
                    console.log('Blur.', editor);
                }}
                onFocus={(event, editor) => {
                    console.log('Focus.', editor);
                }}
            />}   
        </>
    )
}
