export function DMProduct(jsonData) 
{
    this.categoryID = jsonData["categoryID"] 
    this.ek_category_id = jsonData["ek_category_id"] 
    this.ek_category_name = jsonData["ek_category_name"] 
    this.ek_create_date = jsonData["ek_create_date"] 
    this.ek_id = jsonData["ek_id"] 
    this.ek_image_path = jsonData["ek_image_path"] 
    this.ek_is_remove = jsonData["ek_is_remove"] 
    this.ek_product_description = jsonData["ek_product_description"] 
    this.ek_product_id = jsonData["ek_product_id"] 
    this.ek_product_name = jsonData["ek_product_name"] 
    this.ek_remove = jsonData["ek_remove"] 
    this.ek_short_description = jsonData["ek_short_description"] 
    this.price = jsonData["price"] 
    this.productID = jsonData["productID"]
    this.optionGroupOne = jsonData["optionGroupOne"]
    this.optionGroupTwo = jsonData["optionGroupTwo"]
    this.images = jsonData["images"]

}
export function DMBlog(jsonData) 
{
    this.imageURL = jsonData["afasf"] 
}
export function DMMedia(jsonData) 
{
    this.imageURL = jsonData["afasf"] 
}
export function DMOrders(jsonData) 
{
    this.ek_address_id = jsonData["ek_address_id"] 
    this.ek_cargo_number = jsonData["ek_cargo_number"] 
    this.ek_create_date = jsonData["ek_create_date"] 
    this.ek_id = jsonData["ek_id"] 
    this.ek_mail = jsonData["ek_mail"] 
    this.ek_order_id = jsonData["ek_order_id"] 
    this.ek_order_status = jsonData["ek_order_status"] 
    this.ek_password = jsonData["ek_password"] 
    this.ek_payment_type = jsonData["ek_payment_type"] 
    this.ek_phone = jsonData["ek_phone"] 
    this.ek_price = jsonData["ek_price"] 
    this.ek_user_id = jsonData["ek_user_id"] 
    this.ek_user_name = jsonData["ek_user_name"] 
    this.ek_user_surname = jsonData["ek_user_surname"] 
    this.ek_open_address = jsonData["ek_open_address"] 
    this.items = jsonData["items"] 
}
export function DMProductCategory(jsonData) 
{
    this.ek_category_name = jsonData["ek_category_name"] 
    this.ek_id = jsonData["ek_id"] 
    this.ek_remove = jsonData["ek_remove"] 
    this.productCount = jsonData["productCount"] 
}
export function DMProductGroup(jsonData) 
{
    this.ek_option_group_id = jsonData["ek_option_group_id"] 
    this.ek_parent_id = jsonData["ek_parent_id"] 
    this.ek_remove = jsonData["ek_remove"] 
    this.ek_tittle = jsonData["ek_tittle"] 
    this.ek_children =[];
}

export function DMSettings(jsonData) 
{
    this.imageURL = jsonData["afasf"] 
}
export function DMStock(jsonData) 
{
    this.ek_stock_id = jsonData["ek_stock_id"] 
    this.ek_first_option_group = jsonData["ek_first_option_group"] 
    this.ek_second_option_group = jsonData["ek_second_option_group"] 
    this.ek_buy_price = jsonData["ek_buy_price"] 
    this.ek_sell_price = jsonData["ek_sell_price"] 
    this.ek_stock = jsonData["ek_stock"] 
    this.ek_date = jsonData["ek_date"] 
    this.ek_product_id = jsonData["ek_product_id"] 
    this.ek_is_remove = jsonData["ek_is_remove"] 
}


export function DMRequestResponse(response,code,label) {

    this.response =response;
    this.code = code;
    this.label =label;
    
}