import { Button, Modal } from "react-bootstrap";
import { useState } from "react";
import { useEffect } from "react";
import DataManager from "../database/DataManager";
import DatePicker from "react-datepicker";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCamera, faPlusCircle, faRuler, faTrash } from "@fortawesome/free-solid-svg-icons";
import { useRef } from "react";
import AlertEkmekk from "../helper/alert";
import DataCenter from "../Core/Database/DataCenter";

export default function ProductStockInsert(props) {

    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const purchasePrice = useRef(-1);
    const salePrice = useRef(-1);
    const stockNumber = useRef(-1);
    const [inputSingleStockDate, setInputSingleStockDate] = useState(new Date);

    const [category, setCategory] = useState([])
    const [firstSubGroup, setFirstSubGroup] = useState([])
    const [secondSubGroup, setSecondSubGroup] = useState([])

    const [firstSelected, setFirstSelected] = useState([])
    const [secondSelected, setSecondSelected] = useState([])

    // const [firstSelectedID, setFirstSelectedID] = useState([])
    // const [secondSelectedID, setSecondSelectedID] = useState([])

    function ClickEvent() {
        DataCenter().Product.Group.List().then((r)=>{
            console.log("------")
            console.log(r)
            console.log("------")
            r.filter(el => el.ek_option_group_id == props.optionGroupOne[0]).map((item) => {
                setFirstSubGroup(item.ek_children);

            })

            r.filter(el => el.ek_option_group_id == props.optionGroupOne[1]).map((item) => {
                setSecondSubGroup(item.ek_children);

            })
        })
        


    }

    function SelectStock(index, id) {

        if (index == 0) {
            // setFirstSelectedID(id)

            if (id != -1) {
                for (let f = 0; f < firstSubGroup.length; f++) {

                    if (firstSubGroup[f].ek_option_group_id == id) {
                        setFirstSelected(firstSubGroup[f]);
                    }
                }
            }
            else {
                setFirstSelected(null);
            }

        }
        else {
            // setSecondSelectedID(id)

            if (id != -1) {
                for (let f = 0; f < secondSubGroup.length; f++) {

                    if (secondSubGroup[f].ek_option_group_id == id) {
                        setSecondSelected(secondSubGroup[f]);
                    }
                }
            }
            else {
                setSecondSelected(null);
            }

        }

    }

    function AddStock() {
        console.log(props)

        // EĞER FİYAT VE STOK 0 VEYA BOŞSA
        if (purchasePrice.current.value == 0 || purchasePrice.current.value == undefined || salePrice.current.value == 0 || salePrice.current.value == undefined ||
            stockNumber.current.value == 0 || stockNumber.current.value == undefined) {

            AlertEkmekk().FillAll();
        }
        else {

            // EĞER TEK GRUP VARSA VE BOŞ DEĞİLSE
            if (props.groupList.length == 1 && firstSelected.length != 0) {
                var dizi = [];

                dizi.push({
                    item1: firstSelected,
                    item2: secondSelected,
                    buyPrice: purchasePrice.current.value.replace(",", "."),
                    sellPrice: salePrice.current.value.replace(",", "."),
                    stock: stockNumber.current.value,
                    date: inputSingleStockDate,
                })

                setShow( props.insertClick(dizi));
            }
            // EĞER İKİ VE FAZLA GRUP VARSA VE BOŞ DEĞİLSE
            else if (props.groupList.length >= 2 && firstSelected.length != 0 && secondSelected.length != 0) {
                console.log(firstSelected);
                console.log(secondSelected);
                var dizi = [];

                dizi.push({
                    item1: firstSelected,
                    item2: secondSelected,
                    buyPrice: purchasePrice.current.value.replace(",", "."),
                    sellPrice: salePrice.current.value.replace(",", "."),
                    stock: stockNumber.current.value,
                    date: inputSingleStockDate,
                })

                setShow( props.insertClick(dizi));
            }
            else if (props.groupList.length == 0 && props.productType != 2) {

                var dizi = [];

                dizi.push({
                    item1: firstSelected,
                    item2: secondSelected,
                    buyPrice: purchasePrice.current.value.replace(",", "."),
                    sellPrice: salePrice.current.value.replace(",", "."),
                    stock: stockNumber.current.value,
                    date: inputSingleStockDate,
                })

                setShow( props.insertClick(dizi));

            }
            else {
                AlertEkmekk().FillAll();

            }

        }

    }

    return (
        <div>
            <button onClick={() => { handleShow(); ClickEvent(); }} type="reset" className="btn btn-primary mr-2">Stok Ekle</button>

            {/* <div onClick={handleShow}>

                {props.children}
            </div> */}


            <Modal show={show} size="xl" onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Stok Bilgileri</Modal.Title>
                </Modal.Header>
                <Modal.Body>

                    <div className="row">
                        <div className="col-md-12">
                            <div>

                                <div className="row">

                                    {
                                        (props.productType == 2 || props.productType == 3) && props.groupList != undefined && props.groupList.length >= 1 ? <div className="col-md-6 mt-4">
                                            <div className="text  mb-2"><b>{props.groupList[0].ek_tittle} Grubu</b></div>
                                            <select className="w-100 form-control mt-0" onChange={(e) => { SelectStock(0, e.target.value) }}>
                                                <option value={-1}>Lütfen bir seçim yapın</option>
                                                {
                                                    firstSubGroup && firstSubGroup.map((item) => {
                                                        return <option value={item.ek_option_group_id}>{item.ek_tittle}</option>
                                                    })
                                                }
                                            </select>
                                        </div>
                                            : ""
                                    }

                                    {
                                        props.productType == 2 && props.groupList != undefined && props.groupList.length == 2 ? <div className="col-md-6 mt-4">
                                            <div className="text  mb-2"><b>{props.groupList[1].ek_tittle} Grubu</b></div>
                                            {/* <select disabled={0 == 0 ? false : !(0 <= groupList.length)} value={optionGroupOne[0]} className="w-100 form-control mt-0" onChange={(e) => { SelectOption(0, e.target.value); }}> */}
                                            <select className="w-100 form-control mt-0" onChange={(e) => { SelectStock(1, e.target.value) }}>
                                                <option value={-1}>Lütfen bir seçim yapın</option>
                                                {
                                                    secondSubGroup && secondSubGroup.map((item) => {
                                                        return <option value={item.ek_option_group_id}>{item.ek_tittle}</option>
                                                    })
                                                }
                                            </select>
                                        </div>
                                            : ""
                                    }

                                </div>


                                <div className="row mt-4">


                                    <div className="col-md-3">
                                        <label>Alış Fiyatı<span className="text-danger">*</span></label>
                                        <input data-validation-rule="notNull|onlyNumber" placeholder={0} ref={purchasePrice} type="text" name="bash" className="form-control mt-0" />
                                    </div>
                                    <div className="col-md-3">
                                        <label>Satış Fiyatı<span className="text-danger">*</span></label>
                                        <input data-validation-rule="notNull|onlyNumber" placeholder={0} ref={salePrice} type="text" name="bash" className="form-control mt-0" />
                                    </div>
                                    <div className="col-md-3">
                                        <label>Stok Adeti<span className="text-danger">*</span></label>
                                        <input data-validation-rule="notNull|onlyNumber" placeholder={0} ref={stockNumber} type="text" name="bash" className="form-control mt-0" />
                                    </div>
                                    <div className="col-md-3">
                                        <label>Alış Tarihi<span className="text-danger">*</span></label>
                                        <DatePicker dateFormat="dd/MM/yyyy" selected={inputSingleStockDate} className="form-control mt-0" onChange={(date) => setInputSingleStockDate(date)} />
                                    </div>
                                    {/* <div className="col-md-1">
    <FontAwesomeIcon icon={faPlusCircle} />
</div> */}
                                    <hr />


                                </div>



                            </div>
                        </div>

                    </div>



                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Vazgeç
                    </Button>
                    <Button variant="primary" onClick={() => { AddStock(); }}>
                        Ekle
                    </Button>
                </Modal.Footer>
            </Modal>

        </div>
    );
}
