import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import React, { useEffect, useRef, useState } from "react"
import DataCenter from '../../Core/Database/DataCenter';
import { Ckeditor } from '../../component/Ckeditor';
import MediaPicker from '../../component/media_picker';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
export default function SettingsSliderInsert(props) {
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);

    const [item, setItem] = useState({ value: "" });
    const [text, setText] = useState("");
    const [inputCategory, setInputCategory] = useState("");

    function DoneClickEvent() {
        if(props.onDone != null)
        {
            props.onDone(item.value,text);
        }
        setShow(false);
    }

    
    function handleShow() {
        if (props.type == "update") {
            setInputCategory(props.item.ek_category_name);
        }

        setShow(true);
    }
    return (
        <>
        <div className="btn btn-primary"  onClick={handleShow}>
            Yeni Bir Slider Oluştur
        </div>
        

            <Modal show={show} onHide={handleClose} animation={false}>
                <Modal.Header closeButton>
                    <Modal.Title>Slider Ekle</Modal.Title>
                </Modal.Header>
                <Modal.Body>

                    <div className={"px-2 col-md-12"}>
                        <MediaPicker multiple="false" onSelected={(e) => { setItem({ value: e[0].url }) }} >
                            <div className="imagePickerArea d-flex justify-content-center">


                                {
                                    (item.value == undefined || item.value.length == 0) ? <div className="align-self-center w-100 p-4">
                                        <div className="row justify-content-center">
                                            <div className="image-input image-input-outline mr-6 mt-6" id="kt_profile_avatar" style={{ backgroundImage: 'url(/metronic/theme/html/demo7/dist/assets/media/users/blank.png)' }}>
                                                <div className="image-input-wrapper " style={{ backgroundImage: 'url(' + item + ')' }} >



                                                </div>
                                                <label className="btn btn-xs btn-icon btn-circle btn-white btn-hover-text-primary btn-shadow" data-action="change" data-toggle="tooltip" title data-original-title="Change avatar">
                                                    <FontAwesomeIcon icon={faTimes} />
                                                </label>
                                            </div>
                                        </div>

                                    </div> : ""}





                                {
                                    item.value == undefined ? "" : item.value.length != 0 ? <div className="align-self-center w-100 p-4">
                                        <div className="row justify-content-center">

                                            {
                                                <ImagesBox item={item.value.trim().replace(" ", "%20")} />
                                            }
                                        </div>

                                    </div> : ""}

                            </div>
                        </MediaPicker>
                    </div>
                    <div>
                        <Ckeditor editorText={text.value} onChange={(e) => { setText(e) }} />
                    </div>

                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Vazgeç
                    </Button>


                    <Button variant="primary" onClick={DoneClickEvent}>
                        Ekle
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );

    function ImagesBox({ item }) {
        return <div className="image-input image-input-outline mr-6 mt-6 w-100" id="kt_profile_avatar" style={{ backgroundImage: 'url(/metronic/theme/html/demo7/dist/assets/media/users/blank.png)' }}>
            <div className="image-input-wrapper w-100" style={{ height: 300, backgroundSize: "contain", backgroundPosition: "center", backgroundImage: 'url(' + item.trim() + ')' }} />
            <label className="btn btn-xs btn-icon btn-circle btn-white btn-hover-text-primary btn-shadow" data-action="change" data-toggle="tooltip" title data-original-title="Change avatar">
                <FontAwesomeIcon icon={faTimes} />
            </label>
        </div>
    }
}

