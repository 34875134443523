import { selector } from "recoil";
import axios from "axios";
import MyHelper from "../helper/MyHelper";

export const allParametersList = selector({
    key: "allParameters",
    get: async() => {
        const response = await axios.post(MyHelper().URLwithService(), {
                command: 'parameter_get_all',
            })
            .catch(function(error) {
                return error
            })
        const parameters = await response.data;
        return parameters
    }
});