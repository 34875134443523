import DBOrders from "./Orders/DBOrders";
import DBProduct from "./Product/DBProduct";
import DBSettings from "./Settings/DBSettings";
import Partners from "./Partners/Partners";

export default function DataCenter()
{
    return {
        Product : DBProduct(),
        Settings : DBSettings(),
        Orders : DBOrders(),
        Partners : Partners()
    }
}