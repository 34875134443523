import axios from "axios";
import Global from "../../Globa";
import { DMProduct, DMRequestResponse } from "../DataModel/DataModel";
import DBCategory from "./DBCategory";
import DBGroup from "./DBGroup";
import DBImages from "./DBImages";
import DBStock from "./DBStock";

export default function DBProduct()
{
    function Insert(item: DMProduct) : Promise<DMRequestResponse> {

        return new Promise((ok, fail) => {
            axios.post(Global().URL, {
                command: 'product_insert',
                tittle: item.ek_product_name,
                description: item.ek_product_description,
                images: item.images,
                stock: [],
                groupOne: item.optionGroupOne,
                groupTwo: item.optionGroupTwo,
                categoryID: item.categoryID,
                isServices: false,
                servicesPrice: 0
            })
                .then(function (response) {
                    ok(response.data);
                    
                })
                .catch(function (error) {
                    console.log(error);
                });
        })
    
    }
    function Remove(item: DMProduct) : Promise<DMRequestResponse> {
        return new Promise(function (resolve, reject) {
            axios.post(Global().URL, {
                command: 'product_remove',
                productID: item.productID
            })
                .then(function (response) {

                    var response = new DMRequestResponse(true,"","İşlem başarıyla tamamlandı");

                    resolve(response);
                })
                .catch(function (error) {
                    console.log(error);
                });
        });
    
    }

    function Detail(productID)
    {
        return new Promise((ok, fail) => {
            axios.post(Global().URL, {
                command: 'product_detail',
                productID: productID
            })
                .then(function (response) {
                    ok(response.data);
                })
                .catch(function (error) {
                    console.log(error);
                });
        })
    }

    function Update(id, tittle, description, category, isServices, servicesPrice): Promise<DMRequestResponse>
    {
        return new Promise((ok, fail) => {
            axios.post(Global().URL, {
                command: 'product_information_update',
                productID: id,
                productTittle: tittle,
                productDescription: description,
                productCategory: category,
                isServices: isServices,
                servicesPrice: servicesPrice

            })
                .then(function (response) {
                    ok(response.data);
                })
                .catch(function (error) {
                    console.log(error);
                });
        })
    }
    function List(startDate,endDate) : Promise<DMProduct[]> {
        
        return new Promise(function (resolve, reject) {
            axios.post(Global().URL, {
                command: 'product_all',
                startDate: startDate,
                endDate: endDate,
            })
                .then(function (response) {
                    var itemList: DMProduct[] = [];
                    
                    for(var i = 0 ; i<response.data.length;i++)
                    {
                        itemList.push(new DMProduct(response.data[i]))
                    }

                    resolve(itemList);
                })
                .catch(function (error) {
                    console.log(error);
                });
        });
    
    }

    function TrendyolProductInsert()
    {
        
    }

    return {
        Stock : DBStock(),
        Group : DBGroup(),
        Category : DBCategory(),
        Images : DBImages(),
        Update : Update,
        List,
        Remove,
        Insert,
        Detail


    }
}