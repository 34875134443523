import { useState } from "react";
import { useEffect } from "react";
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { faCamera, faPlusCircle, faRuler, faTimes, faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useRef } from "react";
import Validator, { ValidationController } from '../../../Cactus/helper/Validator';
import AlertEkmekk from "../../../Cactus/helper/alert";

import MyHelper from "../../../Cactus/helper/MyHelper";
import { useHistory } from "react-router-dom";
import DataCenter from "../../Core/Database/DataCenter";

export const GroupUpdateModal = (props) => {
    const [show, setShow] = useState(false);
    const [groups, setGroups] = useState();

    const handleClose = () => setShow(false);

    const [subGroup, setSubGroup] = useState([])
    var history = useHistory();

    useEffect(() => {
        DataCenter().Product.List(r => {
            console.log(r);
            setGroups(r);
        })


    }, [])

    const [subGroupIndex, setSubGroupIndex] = useState(0)
    const inputTittle = useRef(null);
    const inputTittleSub = useRef(null);


    function UpdateGroup() {
        DataCenter().Product.Group.Update(props.id, inputTittle.current.value).then(r => {

        })



    }
    const [error, setError] = useState();

    function AddSubGroup() {
        if (inputTittleSub.current.value == "") {
            setError("Lütfen bir değer giriniz");

        }
        else {
            setError();
            var dizi = [];
            dizi["subGroupName"] = inputTittleSub.current.value
            dizi["sub_group_id"] = subGroupIndex
            setSubGroupIndex(subGroupIndex + 1);
            subGroup.push(dizi);
            setSubGroup([...subGroup]);

            DataCenter().Product.Group.SubGroupInsert(props.id, inputTittleSub.current.value).then(r => {
                OpenModal();
            })
        }

    }

    function RemoveSubGroup(id) {
        for (let i = 0; i < subGroup.length; i++) {

            if (subGroup[i].ek_option_group_id == id) {
                subGroup.splice(i, 1);
            }
        }
        setSubGroup([...subGroup])
        DataCenter().Product.Group.SubGroupDelete(id).then(r => {
            MyHelper().AlertSuccess();
        });

    }
    function UpdateSubGroup(id, text) {
        DataCenter().Product.Group.SubGroupUpdate(id, text).then(r => {
            MyHelper().AlertSuccess();
        });
    }

    function handleShow() {
        setShow(true);
    }

    function SubGroupArea() {
        return <>


            <div className="col-md-12 mt-4">
                <div className="text px-2 mb-2"><b>Alt Grup Adı</b></div>
            </div>

            {
                error == undefined ? "" : <div className="col-md-12 text-danger">{error}</div>
            }

            <div className="col-md-12">

                <div class="d-flex bd-highlight">
                    <div class=" flex-fill bd-highlight">
                        <input ref={inputTittleSub} type="text" name="bash" className="form-control" placeholder="Alt Grup Adı" />

                    </div>
                    <div className="btn btn-primary ml-2 h-100" style={{ width: 50 }} onClick={AddSubGroup}>
                        <div class="tittle" > <FontAwesomeIcon icon={faPlusCircle} /></div>
                    </div>
                </div>

            </div>
            <hr />


            {
                subGroup && subGroup.map((item, index) => {
                    return <SubGroupItem item={item} index={index} />
                })
            }

        </>
    }


    function SubGroupItem({ item, index }) {
        return <>
            <div className="col-md-12 mt-4">

                <div class="d-flex bd-highlight">
                    <div class=" flex-fill bd-highlight">
                        <input data-validation-rule="notNull" onChange={(e) => {
                            subGroup[index].ek_tittle = e.target.value;

                        }} defaultValue={item.ek_tittle} type="text" name="bash" className="form-control" placeholder="Alt Grup Adı" />

                    </div>
                    <div className="btn btn-primary ml-2 h-100" style={{ width: 50 }} onClick={() => UpdateSubGroup(item.ek_option_group_id, item.ek_tittle)}>
                        <div class="tittle" > <FontAwesomeIcon icon={faRuler} /></div>
                    </div>
                    <div className="btn btn-primary ml-2 h-100" style={{ width: 50 }} onClick={() => RemoveSubGroup(item.ek_option_group_id)}>
                        <div class="tittle" > <FontAwesomeIcon icon={faTimes} /></div>
                    </div>
                </div>

            </div>


        </>
    }

    function OpenModal() {
        handleShow();
        DataCenter().Product.Group.Detail(props.id).then(r => {
            console.log(r)
            var subDizi = r;

            for (let i = 0; i < r.length; i++) {

                if (r[i].ek_parent_id == -1) {
                    inputTittle.current.value = r[i].ek_tittle;
                    subDizi.splice(i, 1)
                }
            }

            setSubGroup(subDizi);
        })
    }

    return (
        <>


            <a onClick={OpenModal} className="btn btn-light-primary btn-sm " data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">Görüntüle</a>


            <Modal show={show} onHide={handleClose} animation={false}>
                <Modal.Header closeButton>
                    <Modal.Title>Alt Ürün Grubu Güncelle</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Validator>
                        <div className="row">

                            <div className="col-md-12">
                                <div className="text px-2 mb-2"><b>Grup Adı</b></div>
                                <input data-validation-rule="notNull" ref={inputTittle} type="text" name="bash" className="form-control" placeholder="Grup Adı" />
                            </div>

                            <SubGroupArea />
                        </div>
                    </Validator>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Vazgeç
                    </Button>
                    <Button variant="primary" onClick={UpdateGroup}>
                        Güncelle
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
}




