import { Button, Modal } from "react-bootstrap";
import { useState } from "react";
import { useEffect } from "react";
import DataManager from "../database/DataManager";
import { useRef } from "react";
import MaterialTable from "material-table";
import Swal from "sweetalert2";
import MyHelper from "../helper/MyHelper";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";


export default function MediaPicker(props) {


    const [selectedRow, setSelectedRow] = useState(null);

    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const inputImage = useRef(null);
    const [images, setImages] = useState();
    const [selectedImages, setSelectedImages] = useState([])
    function uploadImage() {

        if (inputImage.current.files.length != 0) {

            Swal.fire({
                position: 'top',
                icon: 'info',
                title: 'Lütfen bekleyiniz...',
                showConfirmButton: false,
                allowOutsideClick: false,
                closeClick: false,
                allowEscapeKey: false,

            })




            DataManager().Media.Upload(inputImage.current.files).then((r) => {
                Swal.close()
                Swal.fire({
                    position: 'top-end',
                    icon: 'success',
                    title: 'Başarıyla kaydedildi',
                    showConfirmButton: false,
                    timer: 1500
                })
                DataManager().Media.ListImages().then(r => {

                    r.sort(GetSortOrder("time")); //Pass the attribute to be sorted on    
                    console.log("Sorted Employee Names : ");
                    for (var item in r) {
                        // console.log(r[item].time);
                    }

                    setImages(r);


                })
            })

        }
        else {
            MyHelper().AlertFillAll("Lütfen Önce Resim Seçiniz");
        }



    }


    useEffect(() => {

        DataManager().Media.ListImages().then(r => {

            var newArray = [];
            r.map(item => {
                newArray.push({
                    time: item.time,
                    url: item.url,
                    selected: false
                })
            })

            newArray.sort(GetSortOrder("time")); //Pass the attribute to be sorted on    
            console.log("Sorted Employee Names : ");
            for (var item in newArray) {
                // console.log(r[item].time);
            }


            setImages(newArray);

        })
    }, [])

    function GetSortOrder(prop) {
        return function (a, b) {
            if (a[prop] < b[prop]) {
                return 1;
            } else if (a[prop] > b[prop]) {
                return -1;
            }
            return 0;
        }
    }

    function SelectImage(sItem) {

        // console.log(sItem)
        selectedImages.push(sItem);
        setSelectedImages([...selectedImages])

        /*if (props.multiple) {
 
             var bash = [...images];
             bash.map((item, i) => {
                 if (item.url == sItem.url) {
                     bash[i].selected = !bash[i].selected;
                 }
             })
             setImages(bash);
 
             Done(sItem);
             // handleClose();
         }
         else {
             setShow(false);
 
 
 
             if (props.onSelected != undefined)
                 props.onSelected(sItem);
         }*/

    }

    function Done(sItem) {
        var bash = [];

        images.map((item) => {
            if (item.selected) {
                bash.push(item);
            }
        })
        if (props.onSelected != undefined)
            props.onSelected(sItem);

    }

    function OnOpenModal() {
        handleShow();
        setSelectedImages([]);

        var bash = [...images];
        bash.map((item, i) => {
            if (bash[i].selected) {
                bash[i].selected = false;
            }
        })
        setImages(bash);
    }

    function ImagesBox({ item }) {
        return <div className="image-input image-input-outline mr-6 mt-6" id="kt_profile_avatar" style={{ backgroundImage: 'url(/metronic/theme/html/demo7/dist/assets/media/users/blank.png)' }}>
            <div className="image-input-wrapper" style={{ backgroundImage: 'url(' + item + ')', backgroundSize: "cover", backgroundImage: 'url(' + item + ')' }} />
            <label onClick={(e) => {

                e.stopPropagation();

                for (let i = 0; i < selectedImages.length; i++) {

                    if (item == selectedImages[i].url) {
                        selectedImages.splice(i, 1);
                    }

                }
                setSelectedImages([...selectedImages])
                // setImages([])
            }} className="btn btn-xs btn-icon btn-circle btn-white btn-hover-text-primary btn-shadow" data-action="change" data-toggle="tooltip" title data-original-title="Change avatar">
                <FontAwesomeIcon icon={faTimes} />
            </label>
        </div>
    }
    return (
        <div>
            <div onClick={OnOpenModal}>

                {props.children}
            </div>


            <Modal show={show} size="xl" onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Medya Yöneticisi</Modal.Title>
                </Modal.Header>
                <Modal.Body>

                    <div className="row">
                        <div className="col-md-6 text-center align-items-center">
                            <div>
                                <br />
                                <input ref={inputImage} multiple type="file" />
                            </div>
                        </div>
                        <div className="col-md-3 text-center align-items-center">
                            <div>
                                <br />
                                <div className="btn-primary" onClick={uploadImage} >Yükle</div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-12">
                            <div>


                                <div className="col-md-12">
                                    {
                                        TableList(images)
                                    }

                                </div>


                            </div>
                        </div>

                    </div>
                    <div className="align-self-center w-100 p-4">
                        <div className="row justify-content-center">
                            {
                                selectedImages == undefined ? "" : selectedImages.map((item) => {
                                    return <ImagesBox item={item.url} />
                                })
                            }
                        </div>

                    </div>


                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Vazgeç
                    </Button>
                    <Button variant="primary" onClick={() => { OnClose() }}>
                        Seç
                    </Button>
                </Modal.Footer>
            </Modal>

        </div>


    );

    var isRemove = false;

    function RemoveImage(data) {

        var dataSet = [...images];
        dataSet.map((it, index) => {

            if(it.url == data.url)
            {
                dataSet.splice(index,1);
            }
           
        })
        dataSet.sort(GetSortOrder("time"));
        setImages(dataSet);
        DataManager().Media.DeleteImage(data.url.split('/')[data.url.split('/').length-1])
    }
    function OnClose() {
        if (props.onSelected != undefined) {
            props.onSelected(selectedImages);
        }
        handleClose();
    }

    function TableList(data) {


        // let history = useHistory();

        function OpenUser(index) {

            // <AuthorityControl pageCode={6}>

            // history.push("/dashboard/customer/customer-profile/" + index)

            // </AuthorityControl>

        }
        const columns = [

            /* {
                
               title: <span className="text">Sipariş ID</span>, field: 'user_orderID'
             },*/
            {
                title: <span className="text">Foto</span>, render: data => <div className="d-flex px-2 py-1">
                    <div>
                        <img style={{ maxWidth: '50px', maxHeight: '50px' }} src={data.url} className="avatar avatar-sm me-3" alt="user1" />
                    </div>

                </div>

            },
            {
                title: <span className="text" >İsim</span>, render: data => <div> <p className="text-xs text-secondary mb-0">Foto İsmi</p></div>
            },
            {
                title: <span className="text">Boyut</span>, render: data => <div> <span className="text-secondary text-xs font-weight-bold primary"> 12 kb</span></div>
            },
            {
                title: <span className="text">İşlemler</span>, render: data => <div> <div className="btn btn-primary" onClick={(e) => { e.stopPropagation(); RemoveImage(data) }}>Sil</div> </div>
            },


        ]

        const [rowState, setRowState] = useState({
            columns: columns,
            data: [],
            selected: false,
            selectedRowId: null,
            c: "blue",
            currentRow: {}
        })

        const handleRowClick = (event, rowData) => {
            const index = data.indexOf(rowData)

            // console.log(rowData);
            // rowData.backgroundColor =  "#333"
            // setRowState({ currentRow: rowData });
            // console.log(rowState.tableRef);
            // if (rowData.tableData.id === rowState.selectedRowId) {
            //     setRowState({ selected: false });
            //     setRowState({ selectedRowId: null });
            // } else {
            //     setRowState({ selected: true });
            //     setRowState({ selectedRowId: rowData.tableData.id });
            // }

            // localStorage.setItem("selectedItem", JSON.stringify(data[index]))
            // history.push("/customer/customer-profile");


            /* if (Ekmekk().Profile.userType == UserType.MASTER) {
               history.push("/master/qupo/orders/order-detail/" + data[index].companyID + "/" + data[index].user_orderID);
             }
             else {
               history.push("/business/orders/order-detail/" + data[index].user_orderID);
             }*/


        }

        const Material = (
            <MaterialTable
                data={data}
                columns={columns}
                onRowClick={((evt, selectedRow) => { alert("1"); SelectImage(selectedRow) })}
                options={
                    {
                        filtering: false,
                        search: false,
                        toolbar: false,
                        pageSize: 5,
                        pageSizeOptions: [5, 10],
                        rowStyle: rowData => ({
                            backgroundColor: (selectedRow === rowData.tableData.id) ? '#EEE' : '#FFF'
                        })
                    }
                } />
        )


        return (
            <div>
                <div className="table-responsive">
                    <table className="table table-head-custom table-vertical-center" id="kt_advance_table_widget_2">
                        {Material}
                    </table>
                </div>
            </div>
        )
    }
}
