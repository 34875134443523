import { faCamera, faPlusCircle, faRuler, faMoneyBillAlt, faMoneyCheck, faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect } from "react";
import { useState, useRef } from "react";
import { useParams } from "react-router-dom";
import MyHelper from "../../../Cactus/helper/MyHelper";
import Validator, { ValidationController } from '../../../Cactus/helper/Validator';


import { useRecoilValue, useRecoilState } from "recoil";
import { allParametersList } from "../../../Cactus/global-data/selectors";
import DataCenter from "../../Core/Database/DataCenter";



export default function OrderDetail() {
    const parameterList = useRecoilValue(allParametersList);

    const [data, setData] = useState({});
    const [totalPrice, setTotalPrice] = useState(0);
    const [cargoCode, setCargoCode] = useState(0);
    const [orderStatus, setOrderStatus] = useState(-1);


    let { id } = useParams();
    useEffect(() => {
        DataCenter().Orders.Detail(id).then(r => {
            setData(r);

            console.log(r);
            setCargoCode(r.ek_cargo_number);
            setOrderStatus(r.ek_order_status)
            var to = 0;
            if (r.items != undefined) {
                r.items.map(it => {
                    to += parseFloat(it.ek_price) * parseInt(it.count)
                })

            }
            setTotalPrice(to);

        });
    }, [])

    const [cargoPrice, setCargoPrice] = useState(0)
    const [cargoLimit, setCargoLimit] = useState(0)

    useEffect(() => {
        setCargoPrice(MyHelper().GetCargoPrice(parameterList));
        setCargoLimit(MyHelper().GetCargoLimit(parameterList));
    }, [parameterList])

    function UpdateOrder() {



        var validator = new ValidationController();

        MyHelper().AlertSure((e) => {

            if (e) {

                DataCenter().Orders.UpdateStatus(id, orderStatus).then(r => {
                    console.log(r);
                })

                alert(cargoCode);

                DataCenter().Orders.UpdateCargoNumber(id, cargoCode).then(r => {

                    console.log(r);
                })

            }

        }, "sipariş güncellenecek")




    }


    return <>

        <div className="row">
            <div className="col-md-8">
                <div className="card card-custom gutter-b example example-compact">
                    <div className="card-header">
                        <h3 className="card-title">Ürün Bilgileri</h3>
                        <div className="card-body pt-2">
                            <div className="card-toolbar">



                            </div>

                        </div>
                    </div>
                    <form>
                        <div className="card-body">

                            <div className="table-responsive">
                                <table className="table table-borderless mb-0">
                                    <tbody>
                                        {
                                            data.items != undefined && data.items.map(item => {
                                                return (
                                                    <tr>
                                                        <td className="align-middle w-120px pl-0 pr-2 pb-6">
                                                            <div className="symbol symbol-120 symbol-light-success">
                                                                <div className="symbol-label" style={{ backgroundImage: 'url(' + item.imagePath + ')', backgroundSize: "contain", backgroundColor: "transparent" }} />
                                                            </div>
                                                        </td>
                                                        <td className="align-middle pb-6">
                                                            <div className="font-size-lg font-weight-bolder text-dark-75 mb-1">{item.ek_product_name}</div>
                                                            {item.grp1 == undefined || item.grp1 == null ? "" : <div className="font-weight-bold text-muted"><br />{item.grp1}</div>}
                                                            {item.grp2 == undefined || item.grp2 == null ? "" : <div className="font-weight-bold text-muted"><br />{item.grp2}</div>}

                                                        </td>
                                                        <td className="text-right align-middle pb-6">

                                                            <div className="font-size-lg font-weight-bolder text-dark-75">{item.count} <div className="font-weight-bold text-muted mb-1">Adet</div> </div>
                                                        </td>

                                                        <td className="text-right align-middle pb-6">

                                                            <div className="font-size-lg font-weight-bolder text-dark-75">{item.ek_price} ₺   <div className="font-weight-bold text-muted mb-1">Fiyat</div></div>
                                                        </td>
                                                    </tr>
                                                )
                                            })
                                        }
                                    </tbody>
                                </table>
                            </div>


                        </div>
                        <div className="card-footer">
                            <div className="col-md-12 mt-2 mb-5">
                                <div className="text px-2 mb-2"><b>Kargo Kodu</b></div>
                                <input type="text" name="bash" className="form-control mt-0" onChange={(e) => {
                                    setCargoCode(e.target.value)
                                }} placeholder="Kargo Kodu" value={cargoCode} />
                            </div>
                            <button onClick={UpdateOrder} type="reset" className="btn btn-primary mr-2">Güncelle</button>
                        </div>
                    </form>
                    {/*end::Form*/}
                </div>

            </div>
            <div className="col-md-4">
                <div className="card card-custom gutter-b example example-compact">
                    <div className="card-header">
                        <h3 className="card-title">Teslimat ve Ödeme Bilgileri</h3>
                    </div>
                    {/*begin::Form*/}
                    <form>
                        <div className="card-body">

                            <div class="form-group">
                                <label>İsim Soyisim</label>
                                <p class="form-control-plaintext text-muted">{data.ek_user_name + " " +data.ek_user_surname}</p>
                                <label>Adres</label>
                                <p class="form-control-plaintext text-muted">{data.ek_open_address}</p>
                                <label>Teslimat Durumu</label>
                                <div className="col-md-12">
                                    {/* <div className="text  mb-2"><b> Ürün Grubu</b></div> */}
                                    <select className="w-100 form-control mt-0" onChange={(e) => { setOrderStatus(e.target.value) }} value={orderStatus}>
                                        {/* <select className="w-100 inputArea mt-0" > */}
                                        <option value={-1}>Lütfen bir seçim yapın</option>
                                        <option value={-2}>İptal Et</option>
                                        <option value={0}>Onay Bekliyor</option>
                                        <option value={1}>Hazırlanıyor</option>
                                        <option value={2}>Kargolandı</option>
                                        <option value={3}>Teslim Edildi</option>
                                    </select>
                                </div>
                            </div>
                            <div class="separator separator-dashed my-10"></div>
                            <div class="form-group">
                                <label>Ödenmiş Tutar </label>
                                <p class="form-control-plaintext text-muted">{totalPrice.toFixed(2)}</p>
                                <label>Kargo Bedeli</label>
                                <p class="form-control-plaintext text-muted">
                                    {
                                        cargoLimit > totalPrice ? <>
                                            <FontAwesomeIcon style={{ color: "purple" }} icon={faMoneyBillAlt} className="mx-2 oldPrice" />{cargoPrice.toFixed(2)} ₺
                                        </> : <>


                                            <div className="newPrice2" style={{ fontSize: 16 }}>  <FontAwesomeIcon style={{ color: "purple" }} icon={faMoneyBillAlt} className="mx-2 oldPrice" />   <span className="oldPrice ml-2 mr-2">{cargoPrice.toFixed(2)} ₺</span>0.00 ₺</div>
                                        </>
                                    }
                                </p>
                                <label>Teslimat Durumu</label>
                                <p class="form-control-plaintext text">Toplam Tutar</p>
                                <label>
                                    {
                                        cargoLimit > totalPrice ? (totalPrice + cargoPrice).toFixed(2) : totalPrice.toFixed(2)
                                    }₺
                                </label>
                            </div>


                        </div>
                    </form>
                </div>

            </div>

        </div>
    </>
}