import React, { useEffect, useState } from "react"

import { NavLink, useHistory } from "react-router-dom"
import { Button } from "react-bootstrap"
import MyHelper from "../../helper/MyHelper"
import { useRef } from "react"
import DataCenter from "../../Core/Database/DataCenter"
import { DMProduct, DMProductCategory } from "../../Core/Database/DataModel/DataModel"
export default function ProductList() {
  let history = useHistory()

  const productModel: DMProduct[] = [];
  const productCategoryModel: DMProductCategory[] = [];

  const [productList, setProductList] = useState(productModel);
  const [filterProductList, setFilterProductList] = useState(productModel);
  const [categoryList, setCategoryList] = useState(productCategoryModel);


  useEffect(() => {

    DataCenter().Partners.Trendyol.List("1", "1", "1")
    DataCenter().Product.Category.List().then((r) => {
      setCategoryList(r);
    });

    DataCenter().Product.List("", "").then((r) => {
      setProductList(r);
      r.sort(function (a, b) {
        return b.ek_product_id - a.ek_product_id;
      });
      setFilterProductList(r);
    })

  }, [])

  const inputFilter = useRef(null);
  const inputSort = useRef(null);


  function Filter() {
    setFilterProductList(productList.filter((a) => {
      return inputFilter.current.value == -1 ? true : (a.categoryID == inputFilter.current.value)
    }))

  }
  function Sort() {
    if (inputSort.current.value == 1) {
      filterProductList.sort(function (a, b) {
        return b.ek_product_id - a.ek_product_id;
      });
    }
    else if (inputSort.current.value == 2) {
      filterProductList.sort(function (a, b) {
        return a.ek_product_id - b.ek_product_id;
      });
    }
    else if (inputSort.current.value == 3) {
      filterProductList.sort(function (a, b) {
        return b.price - a.price;
      });
    }
    else if (inputSort.current.value == 4) {
      filterProductList.sort(function (a, b) {
        return a.price - b.price;
      });
    }

    setFilterProductList([...filterProductList])
  }
  return <>
    <div className="row">
      <div className="col-xl-12">
        <div className="card card-custom  gutter-b">
          {/*begin::Header*/}
          <div className="card-header border-0 pt-6 mb-2">
            <h3 className="card-title align-items-start flex-column">
              <span className="card-label font-weight-bold font-size-h4 text-dark-75 mb-3">Ürün Listesi</span>
              <span className="text-muted font-weight-bold font-size-sm"> Sistemde Kayıtlı  {productList.length} Bulunuyor </span>
            </h3>
            <div className="card-toolbar">
              <NavLink to={"/product/insert"} className="btn btn-primary"> Yeni Bir Ürün Oluştur</NavLink>
            </div>
          </div>
          {/*end::Header*/}
          {/*begin::Body*/}
          <div className="card-body pt-2">
            <div className="row">
              <div className="form-group col-md-6 row">

                <label className="col-xl-12 col-lg-12 col-form-label">Kategori Filtreleme</label>
                <div className="col-lg-12 col-xl-12">
                  <select ref={inputFilter} className="form-control form-control-lg form-control-solid" onChange={(e) => {

                    Filter()
                  }}>
                    <option value="-1">Hepsini göster</option>

                    {
                      categoryList.map(item => {
                        return <option value={item.ek_id}>{item.ek_category_name + "(" + item.productCount + ")"}</option>
                      })
                    }
                  </select>
                </div>
              </div>
              <div className="form-group col-md-6 row">
                <label className="col-xl-12 col-lg-12 col-form-label">Sıralama</label>
                <div className="col-lg-12 col-xl-12">
                  <select ref={inputSort} className="form-control form-control-lg form-control-solid" onChange={(e) => {
                    Sort()
                  }}>
                    <option value="1">Önce en yeni</option>
                    <option value="2">Önce en eski</option>
                    <option value="3">Önce en pahalı</option>
                    <option value="4">Önce en ucuz</option>
                  </select>
                </div>
              </div>
            </div>




          </div>
          {/*end::Body*/}
        </div>


      </div>
      <div className="col-xl-12">
        <div className="card card-custom card-stretch gutter-b">
          {/*begin::Header*/}
          <div className="card-header border-0 pt-6 mb-2">
            <h3 className="card-title align-items-start flex-column">
              <span className="card-label font-weight-bold font-size-h4 text-dark-75 mb-3">Ürün Listesi</span>
              <span className="text-muted font-weight-bold font-size-sm"> Sistemde Kayıtlı  {productList.length} Bulunuyor </span>
            </h3>
          </div>
          {/*end::Header*/}
          {/*begin::Body*/}
          <div className="card-body pt-2">
            {/*begin::Table*/}
            <div className="table-responsive">
              <table className="table table-borderless mb-0">
                <tbody>
                  {
                    filterProductList.map((item) => {
                      return <tr>
                        {/*begin::Icon*/}
                        <td className="align-middle w-120px pl-0 pr-2 pb-6">
                          {/*begin::Symbol*/}
                          <div className="symbol symbol-120 symbol-light-success">
                            <div className="symbol-label" style={{ backgroundImage: 'url(' + item.ek_image_path + ')', backgroundSize: "contain", backgroundColor: "transparent" }} />
                          </div>
                          {/*end::Symbol*/}
                        </td>
                        {/*end::Icon*/}
                        {/*begin::Content*/}
                        <td className="align-middle pb-6">
                          <div className="font-size-lg font-weight-bolder text-dark-75 mb-1">{item.ek_product_name}</div>
                          <div className="font-weight-bold text-muted">{item.ek_category_name}</div>
                        </td>
                        <td className="text-right align-middle pb-6">
                          <div className="font-weight-bold text-muted mb-1">Fiyat</div>
                          <div className="font-size-lg font-weight-bolder text-dark-75">{item.price} ₺</div>
                        </td>

                        <td className="text-right align-middle pb-6">
                          <a onClick={() => { history.push("/product/detail/" + item.ek_product_id) }} className="btn btn-light-primary btn-sm " data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">Görüntüle</a>
                          <a onClick={() => {
                            MyHelper().AlertSure((e) => {

                              if (e) {
                                DataCenter().Product.Remove(item).then(r => {
                                  productList.map((subItem, index) => {
                                    if (item.ek_id == subItem.ek_id) {

                                      productList.splice(index, 1);
                                    }
                                  })
                                  setProductList([...productList]);

                                  filterProductList.map((subItem, index) => {
                                    if (item.ek_id == subItem.ek_id) {

                                      filterProductList.splice(index, 1);
                                    }
                                  })
                                  setFilterProductList([...filterProductList]);
                                })


                              }


                            })
                          }} className="btn btn-light-danger ml-2 btn-sm " data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">Sil</a>



                        </td>
                        {/*end::Content*/}
                      </tr>

                    })
                  }


                </tbody>
              </table>
            </div>
            {/*end::Table*/}
          </div>
          {/*end::Body*/}
        </div>
        {/*end::List Widget 20*/}
      </div>

    </div>

  </>
}