import axios from "axios";
import Global from "../../Globa";

export default function DBImages()
{
    function Remove(imageID) : Promise<DMRequestResponse> {
        return new Promise((ok, fail) => {
            axios.post(Global().URL, {
                command: 'product_image_delete',
                imageID: imageID,
            })
                .then(function (response) {
                    console.log(response)
                    ok(response.data);
                })
                .catch(function (error) {
                    console.log(error);
                });
        })
    
    }
    function Insert(productID,imageURL) : Promise<DMRequestResponse> {
        return new Promise((ok, fail) => {
            axios.post(Global().URL, {
                command: 'product_image_insert',
                productID: productID,
                imageURL: imageURL,
            })
                .then(function (response) {
                    ok(response.data);
                })
                .catch(function (error) {
                    console.log(error);
                });
        })
    
    }
    return{
        Insert,Remove
    }
}