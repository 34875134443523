import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import React, { useEffect, useRef, useState } from "react"
import DataManager from '../../database/DataManager';
import { Ckeditor } from '../../component/Ckeditor';
import MediaPicker from '../../component/media_picker';
import Validator, { ValidationController } from '../../helper/Validator';
import MyHelper from '../../helper/MyHelper';
import AlertEkmekk from '../../helper/alert';


export default function CategoryModal(props) {
    const [show, setShow] = useState(false);
    const [editorText, setEditorText] = useState("")
    const [selectedBlog, setSelectedBlog] = useState();
    const [selectedImage, setSelectedImage] = useState();


    const [inputTittleS, setInputTittleS] = useState("");
    const [inputTagS, setInputTagS] = useState("");

    const handleClose = () => setShow(false);
    const [inputCategory, setInputCategory] = useState("");

    function RemoveClickEvent() {

    }

    useEffect(() => {
        if (props.type != "insert") {
            setSelectedBlog(props.data)
            setInputTittleS(props.data.ek_tittle);
            setInputTagS(props.data.ek_tag);
            setEditorText(props.data.ek_text)

        }

    }, [])
    function handleShow() {
        if (props.type == "update") {
            setInputCategory("asfgasfasf");
        }

        setShow(true);
    }

    function ImagePickerSelect(data) {

        alert(JSON.stringify(data))
        setSelectedImage(data[0].url);
    }

    function SubmitBlog() {

        console.log(selectedImage);

        var validator = new ValidationController();

        // TÜM SEÇİMLER YAPILDI MI ? FOTOĞRAF YÜKLENDİ Mİ ? DEVAM EDERSEN KAYDEDİLECEK ?
        if (validator.Control() == 0) {

            if (selectedImage == undefined) {

                MyHelper().AlertFillAll("Lütfen Bir Fotoğraf Yükleyiniz")
            }
            else {

                MyHelper().AlertSure((e) => {

                    if (e && props.type == "insert") {

                        DataManager().Blog.Insert(inputTittleS, inputTagS, editorText, selectedImage.replace(/\n/g, '')).then((r) => {
                            setShow(false)
                            DataManager().Blog.List().then((r) => {
                                props.setBlogList(r);

                            })
                        })

                    } else {
                        DataManager().Blog.Update(inputTittleS, inputTagS, editorText, selectedImage.replace(/\n/g, '')).then((r) => {
                            setShow(false)
                        })
                    }


                }, "blog kaydedilecek")

            }


        }
        else {
            // console.log(selectedImage)
            AlertEkmekk().FillAll();

        }

    }

    return (
        <>
            <Button variant={props.type == "insert" ? "primary " : "light-primary mr-2"} onClick={handleShow}>
                {props.type == "insert" ? "Blog Oluştur" : "Güncelle"}
            </Button>

            <Modal show={show} onHide={handleClose} animation={false}>
                <Modal.Header closeButton>
                    <Modal.Title>Kategori Güncelle</Modal.Title>
                </Modal.Header>
                <Modal.Body>

                    <Validator>
                        <div className="form-group">
                            <MediaPicker multiple={false} onSelected={ImagePickerSelect} >
                                {
                                    selectedImage == undefined ? (selectedBlog != undefined ? <img style={{ maxHeight: '200px', maxWidth: '200px' }} src={selectedBlog.ek_image.replace(/\n/g, '')} /> : <span style={{ cursor: 'pointer' }} className="label label-lg label-light-success label-inline">Resim seç</span>) : <img style={{ maxHeight: '200px', maxWidth: '200px' }} className="img-fluid" src={selectedImage.replace(/\n/g, '')} />
                                }
                            </MediaPicker>
                            <span className="form-text text-muted">Bu alandan resim seçiniz</span>
                        </div>
                        <div className="form-group">
                            <label>Blog Adı
                                <span className="text-danger">*</span></label>
                            <input value={inputTittleS} onChange={(e) => { setInputTittleS(e.target.value) }} type="email" className="form-control" placeholder="Ürün Adı" />
                            <span className="form-text text-muted">Bu alanı boş bırakamazsınız</span>
                        </div>
                        <div className="form-group">
                            <label>Etiketi
                                <span className="text-danger">*</span></label>
                            <input value={inputTagS} onChange={(e) => { setInputTagS(e.target.value) }} type="email" className="form-control" placeholder="Ürün Adı" />
                            <span className="form-text text-muted">Bu alanı boş bırakamazsınız</span>
                        </div>
                        <div className="form-group">
                            <label>Etiketi
                                <span className="text-danger">*</span></label>
                            <Ckeditor onChange={(e) => setEditorText(e)} editorText={editorText} />
                            <span className="form-text text-muted">Bu alanı boş bırakamazsınız</span>
                        </div>
                    </Validator>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Vazgeç
                    </Button>
                    {props.type == "insert" ? "" : <Button variant="danger" onClick={handleClose}>
                        Sil
                    </Button>}

                    <Button variant="primary" onClick={SubmitBlog}>
                        {props.type == "insert" ? "Blog Oluştur" : "Güncelle"}
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
}

