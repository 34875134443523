import axios from "axios";
import MyHelper from '../../helper/MyHelper';

export default function DBParamaters()
{
    
    function GetAll()
    {
        
        return new Promise((ok, fail) => {
            axios.post(MyHelper().URLwithService(), {
              command: 'parameter_get_all',
            })
              .then(function (response) {
      
                ok(response.data);
              })
              .catch(function (error) {
                console.log(error);
              });
          })
    }
    function Get(key)
    {
        return new Promise((ok, fail) => {
            axios.post(MyHelper().URLwithService(), {
              command: 'parameter_get_all',
              paramater_key : key,
            })
              .then(function (response) {
      
                console.log(response.data)
                ok(response.data);
              })
              .catch(function (error) {
                console.log(error);
              });
          })
    }
    function Set(key,value)
    {
        return new Promise((ok, fail) => {
            axios.post(MyHelper().URLwithService(), {
              command: 'parameter_set',
              paramater_key : key,
              paramater_value : value,
            })
              .then(function (response) {
      
                // console.log(response.data)
                ok(response.data);
              })
              .catch(function (error) {
                console.log(error);
              });
          })
    }

    return {
        GetAll,Get,Set
    }
}