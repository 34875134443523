import { useState } from "react";
import { useEffect } from "react";
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { faCamera, faPlusCircle, faRuler, faTimes, faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import DataManager from "../../../Cactus/database/DataManager";
import { useRef } from "react";
import Validator, { ValidationController } from '../../../Cactus/helper/Validator';
import AlertEkmekk from "../../../Cactus/helper/alert";

import MyHelper from "../../../Cactus/helper/MyHelper";
import { useHistory } from "react-router-dom";
import DataCenter from "../../Core/Database/DataCenter";

export const GroupInsertModal = (props) => {
    const [show, setShow] = useState(false);
    

    const handleClose = () => setShow(false);

    const [subGroup, setSubGroup] = useState([])
    var history = useHistory();

    useEffect(() => {
       
        

    }, [])

    const [subGroupIndex, setSubGroupIndex] = useState(0)
    const inputTittle = useRef(null);
    const inputTittleSub = useRef(null);


    function InsertGroup() {

        var validator = new ValidationController();

        if (validator.Control() == 0) {

            MyHelper().AlertSure((e) => {

                if (e) {

                    var dizi = []
                    subGroup.map(r => {
                        dizi.push({ subGroupName: r.subGroupName });
                    })

                    DataCenter().Product.Group.Insert(inputTittle.current.value, dizi).then(r => {
                        console.log(r);
                        history.push("/product/sub-group")
                        setShow(false);
                        props.onDone(true);
                    })

                }


            }, "grup kaydedilecek")



        }
        else {
            AlertEkmekk().FillAll();

        }

    }
    const [error,setError] = useState();

    function AddSubGroup() {
        if(inputTittleSub.current.value == "")
        {
            setError("Lütfen bir değer giriniz");
           
        }
        else
        {
            setError();
            var dizi = [];
            dizi["subGroupName"] = inputTittleSub.current.value
            dizi["sub_group_id"] = subGroupIndex
            setSubGroupIndex(subGroupIndex + 1)
            subGroup.push(dizi)
            setSubGroup([...subGroup])
        }
       
    }

    function RemoveSubGroup(index) {
        for (let i = 0; i < subGroup.length; i++) {

            if (subGroup[i].sub_group_id == index) {
                subGroup.splice(i, 1);
            }
        }
        setSubGroup([...subGroup])

    }

    function handleShow() {
        setShow(true);
    }

    function SubGroupArea() {
        return <>


            <div className="col-md-12 mt-4">
                <div className="text px-2 mb-2"><b>Alt Grup Adı</b></div>
            </div>

            {
                error == undefined ? "" : <div className="col-md-12 text-danger">{error}</div>
            }

            <div className="col-md-12">

                <div class="d-flex bd-highlight">
                    <div class=" flex-fill bd-highlight">
                        <input ref={inputTittleSub} type="text" name="bash" className="form-control" placeholder="Alt Grup Adı" />

                    </div>
                    <div className="btn btn-primary ml-2 h-100" style={{width:50}}  onClick={AddSubGroup}>
                        <div class="tittle" > <FontAwesomeIcon icon={faPlusCircle} /></div>
                    </div>
                </div>

            </div>
            <hr/>


            {
                subGroup && subGroup.map((item) => {
                    return <SubGroupItem item={item} />
                })
            }

        </>
    }


    function SubGroupItem({ item }) {
        return <>

            <div className="col-md-12 mt-4">

                <div class="d-flex bd-highlight">
                    <div class=" flex-fill bd-highlight">
                    <input data-validation-rule="notNull" defaultValue={item.subGroupName} type="text" name="bash" className="form-control" placeholder="Alt Grup Adı" />
          
                    </div>
                    <div className="btn btn-primary ml-2 h-100" style={{width:50}} onClick={() => RemoveSubGroup(item.sub_group_id)}>
                        <div class="tittle" > <FontAwesomeIcon icon={faTimes} /></div>
                    </div>
                </div>

            </div>
           

        </>
    }

    return (
        <>
            <a onClick={handleShow} className="btn btn-primary font-weight-bolder font-size-sm">
                <span className="svg-icon svg-icon-md svg-icon-white">
                </span>Yeni Grup Oluştur</a>

            <Modal show={show} onHide={handleClose} animation={false}>
                <Modal.Header closeButton>
                    <Modal.Title>Alt Ürün Grubu Oluştur</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Validator>
                        <div className="row">

                            <div className="col-md-12">
                                <div className="text px-2 mb-2"><b>Grup Adı</b></div>
                                <input data-validation-rule="notNull" ref={inputTittle} type="text" name="bash" className="form-control" placeholder="Grup Adı" />
                            </div>

                            <SubGroupArea />
                        </div>
                    </Validator>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Vazgeç
                    </Button>
                    <Button variant="primary" onClick={InsertGroup}>
                        Ekle
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
}




