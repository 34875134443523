import Swal from 'sweetalert2/dist/sweetalert2.all.min.js'

import 'sweetalert2/dist/sweetalert2.min.css';



export default function MyHelper() {

    function URL() {
        return "https://ihtiyacvarsa.com"
        return "http://parodia.cactus.com.tr/skylinecaravan/index.php"
        return "https://kahvenindelisi.com"
    }

    function URLwithoutS() {
        return "http://ihtiyacvarsa.com"
        return "http://parodia.cactus.com.tr/skylinecaravan/index.php"
        return "http://kahvenindelisi.com"

    }

    function URLwithService() {
        return "https://ihtiyacvarsa.com/services/index.php"
        return "http://parodia.cactus.com.tr/skylinecaravan/index.php"
        return "https://kahvenindelisi.com/services/index.php"
    }

    function IsLogin() {
        // console.log(localStorage.getItem("kahvenin_delisi_user_id"))
        if (localStorage.getItem("kahvenin_delisi_user_id") == undefined || localStorage.getItem("kahvenin_delisi_user_id") == "undefined") {
            return false;
        }
        else {
            return true;
        }
    }

    function GetCargoLimit(parameterList) {
        if (parameterList != undefined)
            return parseFloat(parameterList.filter(item => item.ek_key == "businessCargoMinimumLimit")[0].ek_value + "");
        else
            return 0;
    }
    function GetCargoPrice(parameterList) {
        if (parameterList != undefined)
            return parseFloat(parameterList.filter(item => item.ek_key == "businessCargoPrice")[0].ek_value + "");
        else
            return 0;
    }
    function GetCargoDate(parameterList) {
        console.log(parameterList);
        return parseFloat(parameterList.filter(item => item.ek_key == "businessCargoDay")[0].ek_value + "");

    }
    function GetThemeColor(parameterList) {
        // console.log(parameterList);
        if (parameterList != undefined)
            return parameterList.filter(item => item.ek_key == "themeColor")[0].ek_value + "";
        else
            return ""
    }

    function SetLogin(userID, userName) {
        localStorage.setItem("kahvenin_delisi_user_id", userID);
        localStorage.setItem("kahvenin_delisi_user_name", userName);
    }

    function LogOut() {
        localStorage.setItem("kahvenin_delisi_user_id", undefined);
        localStorage.setItem("kahvenin_delisi_user_name", undefined);
    }

    function GetUserName() {
        return localStorage.getItem("kahvenin_delisi_user_name");
    }

    function GetUserID() {
        return localStorage.getItem("kahvenin_delisi_user_id");
    }

    function AlertSuccess(tittle) {
        Swal.fire({
            position: 'top-end',
            icon: 'success',
            title: tittle == undefined ? 'İşlem başarılı' : tittle,
            showConfirmButton: false,
            timer: 1500
        })
    }
    function AlertFail(tittle, description) {
        Swal.fire({
            position: 'top-end',
            icon: 'error',
            title: tittle == undefined ? 'İşlem başarısız' : tittle,
            showConfirmButton: false,
            timer: 1500
        })
    }
    function AlertFillAll(tittle) {
        Swal.fire({
            title: tittle == undefined ? 'Lütfen Tüm Seçimleri Yapınız' : tittle,
            icon: 'warning',
        })
    }
    function AlertSure(callBackFunction, tittle) {
        Swal.fire({
            title: 'Emin Misin?',
            text: "Kabul edersen " + tittle + " !",
            icon: 'question',
            showCancelButton: true,
            confirmButtonText: 'Evet, Kaydet!',
            cancelButtonText: 'Hayır, İptal!',
            confirmButtonColor: '#26C281',
            cancelButtonColor: '#D91E18',
            reverseButtons: true
        }).then((result) => {

            if (result.value) {
                Swal.fire({
                    position: 'top-end',
                    icon: 'success',
                    title: 'İşlem Başarıyla Gerçekleşti',
                    showConfirmButton: false,
                    timer: 1500
                })

                // Swal.fire(
                //     'Başarılı!',
                //     'İşlem Başarıyla Gerçekleşti.',
                //     'success'
                // )
                // For more information about handling dismissals please visit
                // https://sweetalert2.github.io/#handling-dismissals
            } else if (result.dismiss === Swal.DismissReason.cancel) {
                Swal.fire({
                    position: 'top-end',
                    icon: 'error',
                    title: 'İşlem İptal Edildi',
                    showConfirmButton: false,
                    timer: 1500
                })

                // Swal.fire(
                //     'İptal Edildi',
                //     'İşleminize Devam Edebilirsiniz',
                //     'error'
                // )
            }

            callBackFunction(result.value);


        })
    }

    return {
        IsLogin, SetLogin, GetUserName, LogOut, GetUserID, AlertSuccess, AlertFail, AlertSure, AlertFillAll, GetCargoLimit, GetCargoPrice, GetCargoDate, URL, URLwithoutS, URLwithService, GetThemeColor
    }
}